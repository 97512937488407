import React, { useState, useEffect } from 'react'
import Header from "../Components/Head";
import Table from 'react-bootstrap/Table';
import axios from 'axios';
import ReactPaginate from "react-paginate";
import Moment from "react-moment";


function Userdata() {
    const [data, setdata] = useState([])
    const [pageNumber, setPageNumber] = useState(0);

    const [listPerPage] = useState(10);
    const pagesVisited = pageNumber * listPerPage;
    const lists = data.slice(pagesVisited, pagesVisited + listPerPage);
    const pageCount = Math.ceil(data.length / listPerPage);
    const changePage = ({ selected }) => {
        setPageNumber(selected);
    };

    const usersdata = () => {
        axios.get("https://api.digitalraiz.co.in/api/user/leads/data").then((res) => {
            console.log(res.data)
            setdata(res.data.data)
        })
    }

    useEffect(() => {
        usersdata()
    }, [])

    return (
        <div>
            <Header />

            <div className='container-flied' >
                <div style={{ paddingTop: "120px" }}>
                    <div className='card contact-form-block'>
                        <div  >
                            <Table striped bordered hover>
                                <thead >
                                    <tr>
                                        <th>Sl.No</th>
                                        <th>FULL NAME</th>
                                        <th>MOBILE</th>
                                        <th>EMAIL ID</th>
                                        <th>SERVICES</th>
                                        <th>DATE</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {lists.map((data, x) => {
                                        return <tr key={x}>
                                            <td>{(pageNumber - 1) * 10 + x + 11}</td>
                                            <td>{data.name}</td>
                                            <td>{data.phone}</td>
                                            <td>{data.email}</td>
                                            <td>{data.service}</td>
                                            <td>
                                            <Moment format="DD/MM/YYYY hh:mm A">
                                                {data.updatedAt}
                                                </Moment>
                                                </td>

                                        </tr>
                                    })}
                                </tbody>
                            </Table>
                            <div className="mt-3" style={{ float: "right" }}>
                                {/* <Stack spacing={2}> */}
                                <ReactPaginate
                                    previousLabel={"Previous"}
                                    nextLabel={"Next"}
                                    pageCount={pageCount}
                                    onPageChange={changePage}
                                    containerClassName={"pagination"}
                                    previousLinkClassName={"previousBttn"}
                                    nextLinkClassName={"nextBttn"}
                                    disabledClassName={"disabled"}
                                    activeClassName={"active"}
                                    total={lists.length}
                                />
                                {/* </Stack> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default Userdata