import React, { useState, useEffect } from "react";
import Drawer from "./Drawers";
import Box from "@mui/material/Box";
import { NavLink } from "react-router-dom";
import CssBaseline from "@mui/material/CssBaseline";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ErrorIcon from "@mui/icons-material/Error";
import HomeIcon from "@mui/icons-material/Home";

function Profile() {
  const [form, setform] = useState({});
  const [user, setuser] = useState([]);
  console.log(user);

  const handleChange = (e) => {
    let myUser = { ...user };
    myUser[e.target.name] = e.target.value;
    setuser(myUser);
  };

  const handleSubmit = (e) => {
    updateCategory();
    e.preventDefault();
  };

  const addCategory = () => {
    var token = sessionStorage.getItem("token");
    axios
      .post(
        "https://api.digitalraiz.co.in/api/admin/digitalraiz/changepassword",
        {
          currentPassword: form.currentPassword,
          newPlainPassword: form.newPlainPassword,
          confirmPassword: form.confirmPassword,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(
        (res) => {
          if (res.status === 200) {
            toast(res.data.message);
          }
        },
        (error) => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message);
          }
        }
      );
  };

  const handleChange1 = (e) => {
    let myUser = { ...form };
    myUser[e.target.name] = e.target.value;
    setform(myUser);
  };

  const handleSubmit1 = (e) => {
    e.preventDefault();

    addCategory();
  };

  const updateCategory = () => {
    const data1 = user._id;
    const zzz = {
      username: user.username,
      contactNumber: user.contactNumber,
      email: user.email,
    };
    var token = sessionStorage.getItem("token");
    axios
      .put(
        'https://api.digitalraiz.co.in/api/admin/digitalraiz/get-profile' +
          "/" +
          data1,
        zzz,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(
        (res) => {
          if (res.status === 200) {
            toast(res.data.message);
            getCategory();
          }
        },
        (error) => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message);
          }
        }
      );
  };

  useEffect(() => {
    getCategory();
  }, []);

  const getCategory = () => {
    var token = sessionStorage.getItem("token");
    var _id = sessionStorage.getItem("AdminId");
    axios
      .post(
        "https://api.digitalraiz.co.in/api/admin/digitalraiz/get-profile",
        { _id },

        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((res) => {
        setuser(res.data.ProfileResult);
      });
  };

  return (
    <div>
      {" "}
      <>
        <Box
          sx={{ display: "flex" }}
          style={{ background: "#ebeaea", padding: "111px 0 0 0" }}
          className="cardmrg"
        >
          <Drawer></Drawer>
          <CssBaseline />
          <Box
            component="main"
            style={{ background: "#ebeaea" }}
            sx={{ flexGrow: 2, p: 4 }}
          >
            <>
              <div className="card rrr">
                <div style={{ padding: "20px" }}>
                  <div className="row" style={{ padding: "20px" }}>
                    <div className="col">
                      <h4>Settings</h4>
                    </div>
                    <div className="col">
                      <NavLink to="/Admin">
                        <button
                          className="btn btn "
                          style={{
                            float: "right",
                            background: "rgb(144,30,193)",
                            color: "white",
                          }}
                        >
                          <HomeIcon />
                          {/* Dashboard */}
                        </button>
                      </NavLink>
                    </div>
                  </div>

                  <div className="row ">
                    <div className="col-lg-6" style={{ padding: "10px" }}>
                      <form
                        method="post"
                        onSubmit={(e) => {
                          handleSubmit(e);
                        }}
                      >
                        <div>
                          <p style={{ fontSize: "20px" }}>
                            <ErrorIcon /> Basic information
                          </p>
                          <br />
                          <hr />

                          <div>
                            <div className=" mt-4">
                              <label>Full Name :</label>
                              <input
                                type="text"
                                value={user.username}
                                name="username"
                                required
                                className="form-control"
                                onChange={(e) => {
                                  handleChange(e);
                                }}
                              />
                            </div>
                            <div className=" mt-4">
                              <label>Phone</label>
                              <input
                                type="text"
                                value={user.contactNumber}
                                name="contactNumber"
                                required
                                className="form-control "
                                onChange={(e) => {
                                  handleChange(e);
                                }}
                              />{" "}
                            </div>
                            <div className=" mt-4">
                              <label>Email:</label>
                              <input
                                type="text"
                                value={user.email}
                                required
                                name="email"
                                className="form-control"
                                onChange={(e) => {
                                  handleChange(e);
                                }}
                              />
                            </div>
                            <button
                              className="btn  mt-5"
                              style={{
                                float: "right",
                                color: "white",
                                background: "rgb(144,30,193)",
                              }}
                              type="submit"
                            >
                              Save & Change
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                    <div className="col-lg-6" style={{ padding: "10px" }}>
                      <form
                        method="post"
                        onSubmit={(e) => {
                          handleSubmit1(e);
                        }}
                      >
                        <div>
                          <p style={{ fontSize: "20px" }}>
                            Change your password
                          </p>
                          <br />
                          <hr />

                          <div>
                            <div className=" mt-4">
                              <label>Old password</label>
                              <input
                                type="text"
                                value={form.currentPassword}
                                placeholder="Enter new password"
                                className="form-control"
                                name="currentPassword"
                                required
                                onChange={(e) => {
                                  handleChange1(e);
                                }}
                              />{" "}
                            </div>
                            <div className=" mt-4">
                              <label>New password</label>
                              <input
                                type="text"
                                value={form.newPlainPassword}
                                name="newPlainPassword"
                                required
                                onChange={(e) => {
                                  handleChange1(e);
                                }}
                                placeholder="Conform your new password"
                                className="form-control"
                              />
                            </div>
                            <div className=" mt-4">
                              <label>Confirm password</label>
                              <input
                                type="text"
                                value={form.confirmPassword}
                                name="confirmPassword"
                                required
                                onChange={(e) => {
                                  handleChange1(e);
                                }}
                                placeholder="Conform your new password"
                                className="form-control"
                              />
                            </div>
                            <button
                              className="btn  mt-5"
                              style={{
                                float: "right",
                                color: "white",
                                background: "rgb(144,30,193)",
                              }}
                            >
                              Save & Change
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </>
            <ToastContainer />
          </Box>
        </Box>
      </>
    </div>
  );
}

export default Profile;
