import React, { useState, useEffect } from 'react'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Slider from "react-slick";
import logo from '../assets/images/newimgs/logo.png';
// import logo from '../assets/images/logo.png'
import author1 from "../assets/images/blog/author-1.jpg";
import author2 from "../assets/images/blog/author-2.jpg";
import author3 from "../assets/images/blog/author-3.jpg";
import author4 from "../assets/images/blog/author-4.jpg";
// import author5 from "../assets/images/blog/author-5.jpg";
import axios from "axios";
// import { ToastContainer, toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
import toast, { Toaster } from 'react-hot-toast';
import "../assets/css/style.css";
import DigitalRaizpdf from "../assets/images/newimgs/1. Company Profile Final One.pdf";
import DigitalraizCreativeSolutions from "../assets/images/newimgs/digitalraiz/DigitalraizCreativeSolutions.pdf";
import { NavLink, useNavigate } from "react-router-dom";

function Header() {
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [form, setform] = useState({
    "name": "",
    "phone": "",
    "email": "",
    "service": "Mobile Application",
  });
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [sticky, setSticky] = useState("");


  useEffect(() => {
    handleShow();

    window.addEventListener("scroll", isSticky);
    return () => {
      window.removeEventListener("scroll", isSticky);

    };


  }, []);
  const isSticky = () => {
    const scrollTop = window.scrollY;
    const stickyClass = scrollTop >= 200 ? "header-area sticky" : "";
    setSticky(stickyClass);
    console.log(stickyClass);
  };

  const classes = `${sticky}`;


  const settings1 = {
    // autoplay: true,
    // autoplaySpeed: 8000,
    // speed: 500,
    // infinite: true,
    // dots: true,
    // width:400,
    // // fade: true,
    // arrows: false,
    // slidesToShow: 3,
    // slidesToScroll: 1,

    dots: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 3500,
    pauseOnFocus: false,
    pauseOnHover: false,
    pauseOnDotsHover: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    width: 500,
    // responsive: [
    //   {
    //     breakpoint: 767,
    //     settings: {
    //       arrows: false,

    //       slidesToShow: 3,
    //     },
    //   },
    //   {
    //     breakpoint: 480,
    //     settings: {
    //       arrows: false,
    //       slidesToShow: 1,
    //     },
    //   },
    // ],
    responsive: [{
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 1
      }
    }]
  };

  const handlechange = (e) => {
    let myUser = { ...form };
    myUser[e.target.name] = e.target.value;
    setform(myUser);
  }

  const addUsers = () => {
    var params = {
      "name": form.name,
      "phone": form.phone,
      "email": form.email,
      "service": form.service,
    }
    axios.post("https://api.digitalraiz.co.in/api/user/leads/addLead", params, {

    }).then((res) => {
      console.log("success")
      toast.success("Added Successfully. Your data received, Our team will get back to you soon!");
      // userdata();
      handleClose();
      navigate('/thankyou')
    })

  }

  const usersSubmit = (e) => {
    e.preventDefault();
    addUsers();
  };


  return (
    <div>
      <header className={classes} id="header-area">

        <nav className="navbar navbar-expand-md fixed-top">
          <div className="container">
            <div className='row' style={{ width: "100%" }}>
              <div className='col-3'>

                <div className=""><NavLink to="/" className="navbar-brand" ><img src={logo} className="img-fluid moblogoimg" alt="Img" /></NavLink></div>
              </div>
              <div className='col-9'>
                {/* <a className="btn themes-btn btn-block headbtn" style={{border:"none",background:"none"}}><a  className=''><i class="fa fa-phone mr-1" aria-hidden="true"></i>+91 9494613601</a></a> */}
                <div className='headtab' style={{ float: "right" }}>

                  {/* <p> <i class="fa fa-phone mr-1" aria-hidden="true"></i>+91 9494613601</p> */}
                  <div className='row mt-3 mobtab' >
                    <div className='col-4 mobileslider' style={{ marginTop: "10px" }}>
                      <a href='tel:+91 9494613601' className='contactnumber '> <i class="fa fa-phone mr-1 " aria-hidden="true"></i>+91 9494613601</a>
                    </div>

                    <div className='col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4 headerbtnsm'>

                      <div className="control-form">
                        <a href={DigitalRaizpdf} target="_blank">
                          <button className="btn themes-btn btn-block headbtn"><a className='btna'>Company Profile <i class="fa fa-cloud-download ml-1" aria-hidden="true"></i></a></button>
                        </a>
                      </div>


                    </div>

                    <div className='col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4'>
                      <div className="control-form btnmar ">
                        <a href={DigitalraizCreativeSolutions} target="_blank">
                          <button className="btn theme-btn btn-block headbtn1"><span className='btna text-white' >Portfolio <i class="fa fa-arrow-right ml-1" aria-hidden="true"></i></span></button>
                        </a>
                      </div>
                      {/* </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </nav>

      </header>

      <Modal
        size="lg"
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        className="mt-5"
      >

        {/* <div onClick={handleClose}>
          <i class="fa fa-times modalstyle " aria-hidden="true" ></i>
        </div> */}
        <div className="row login-page ">
          <div className="col-md-5 mobileslider cross">
            <div className=' login-side-des'>
              <div className="container-fluid">
                <div className="login-side-block">
                  <a href=""><img src={logo} alt="Logo" /></a>
                  <div className="login-reviews">
                    <div className="review-details-content">
                      <div className="owl-carousel review_details" id="review_details-2">
                        <Slider {...settings1} >
                          <div className="item">
                            <p>"Our Dr.Narayana group of institutions were very happy with digital raiz solutions.. very satisfied with their work and response..."</p>
                            <h5>Sravani Reddy</h5>
                            <h6>Vice Princile of Dr.Narayana group of institutions</h6>
                          </div>

                          <div className="item">
                            <p>" Digital Raiz team developed a mobile app for me. They are the best in app development more over they save my money and time. I suggest Digital Raiz for mobile app development."</p>
                            <h5>Ganesh</h5>
                            <h6>Chairmen of TaxiGk</h6>
                          </div>

                          <div className="item">
                            <p>"Best website design company in Hyderabad...I am on of the client in Digital Raiz creative solutions, its a good customer services. our project will done at limited. Thankyou digital Raiz creative solutions for your support."</p>
                            <h5>Abdullah</h5>
                            <h6>CEO of Iotroncs</h6>
                          </div>

                          <div className="item">
                            <p>"Digital Raiz made a website and mobile app for us. Product is excellent and their support is outstanding. Thanks to staff and management."</p>
                            <h5> Bhupesh </h5>
                            <h6>Md of SLN Services</h6>
                          </div>

                          <div className="item">
                            <p>"Very efficient and reliable team. Definitely recommend Digital Raiz Creative Solutions for those who are looking for digital marketing services within budget and on time. Excellent client services and support provided. Thanks Team!"</p>
                            <h5>Bala Koteshwara Rao</h5>
                            <h6>MD of Thrilokmart</h6>
                          </div>

                          <div className="item">
                            <p>"They procure a Very Good team in digital marketing and web development. I recommend my customers to join hands with Digital Raiz"</p>
                            <h5>Sai Kiran</h5>
                            <h6>MD of Vedithtech</h6>
                          </div>
                          <div className="item">
                            <p>"Good quality best team members in time pra project submission.......Thank You DigitalRaiz."</p>
                            <h5>Manikanta</h5>
                            <h6>Chairmen of SMV Chemical</h6>
                          </div>
                          <div className="item">
                            <p>"DigitalRaiz developed my app name SIFALO, within time at a low cost. I suggested this company for mobile applications. "</p>
                            <h5>Khalil Ahmed</h5>
                            <h6>Somalia VP of Sifalo</h6>
                          </div>
                          <div className="item">
                            <p>"We were truly impressed! The way digital Raiz developed my mobile application in the name of EXMOB, with less cost, on-time delivery, and finally, the way of conducting meetings and gathering the requirement was excellent. Thank you so much, digital raiz team."</p>
                            <h5>Nawaz</h5>
                            <h6>Chairmen of EXMOB</h6>
                          </div>
                          <div className="item">
                            <p>"One of the best digital marketing agencies to generate potential leads through google ads is DigitalRaiz. Good Job Done."</p>
                            <h5>Nawaf</h5>
                            <h6>UAE</h6>
                          </div>
                          <div className="item">
                            <p>"We have hired them for our Mobile application development work,
                              They are productive and punctual in work they have completed our new mobile application very quickly. I truly recommend the digital raiz for abroad clients."</p>
                            <h5>Branty Bruno</h5>
                            <h6>France CEO of Decohindi</h6>
                          </div>

                        </Slider>

                      </div>

                    </div>
                    <p class='visible' onClick={handleClose}>.</p>
                  </div>

                </div>

              </div>

            </div>

          </div>

          <div className="col-md-7" >
            <div className="container">

              <a href="" className="res-logo"><img src={logo} alt="Logo" /></a>
              <div className="login-form modheight">
                <div className="login-form-head ">
                  <h2 className='mt-5'><span className='h2txt'>Welcome to</span> <span>DigitalRaiz</span></h2>
                  <p >Fill out the form to get started..</p>
                </div>
                <form onSubmit={usersSubmit}>
                  <div className="form-group">
                    <label className="form-label"
                      htmlFor="signinEmail"
                    >Full Name</label>
                    <div className="input-group">
                      <div className="input-icon">
                        <span className="fa fa-user-o" />
                      </div>
                      <input type="text" pattern="^[\w'\-,.][^0-9_!¡?÷?¿/\\+=@#$%ˆ&*(){}|~<>;:[\]]{2,}$" className="form-control" name="name" onChange={(e) => { handlechange(e); }} id="signinEmail" placeholder="Full Name" aria-label="Email address" required />
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="form-label" htmlFor="signinPassword">
                      Mobile
                    </label>
                    <div className="input-group">
                      <div className="input-icon">
                        <span className="fa fa-mobile" />
                      </div>
                      <input type="text" minlength="0" maxlength="10" pattern="\d{10}" className="form-control" name="phone" id="signinPassword" onChange={(e) => { handlechange(e); }} placeholder="Mobile No..." aria-label="Password" required />
                    </div>
                  </div>
                  {/* <div className="form-group">
                    <label className="form-label" htmlFor="signinPassword2"> Email Id</label>
                    <div className="input-group">
                      <div className="input-icon">
                        <span className="fa fa-envelope-o" />
                      </div>
                      <input type="email" pattern="(^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$)" className="form-control" name="email" id="signinPassword2" onChange={(e) => { handlechange(e); }} placeholder="Email Id" aria-label="Password" required />
                    </div>
                  </div> */}
                  <div className="form-group">
                    <label className="form-label" htmlFor="signinPassword2">Services</label>
                    <div className="input-group">
                      <div className="input-icon">
                        <span className="fa fa-globe" />
                      </div>
                      <select className="form-control" value={form.service} name="service" id="signinPassword2" placeholder="Email Id" onChange={(e) => { handlechange(e); }} aria-label="Password" required >
                        <option value="">Select</option>
                        <option

                          value="Mobile Application"
                        >
                          Mobile Application
                        </option>
                        <option
                          className="form-control"
                          value="Website Design & Development"
                        >
                          Website Design & Development
                        </option>
                        <option
                          className="form-control"
                          cvalue="Digital Marketing"
                        >
                          Digital Marketing
                        </option>
                        <option className="form-control" cvalue="Others">Others</option>
                      </select>
                    </div>
                  </div>
                  <div className="form-group">
                  </div>
                  <div className="form-group">
                    <button className="btn theme-btn btn-block" type='submit'>Submit</button>
                  </div>
                </form>
                <p class='visible' onClick={handleClose}>.</p>
              </div>
            </div>
          </div>
        </div>

      </Modal>

      <Toaster
        position="top-right"
        reverseOrder={false}
      />
    </div>
  )
}

export default Header