import React, { useState, useEffect } from "react";
import Header from "../Components/Header";
import Footer from "../Components/Footer";
import Slider from "react-slick";
import Modal from "react-bootstrap/Modal";
import Creative from "../Components/Creative1";
import bannerbg from "../assets/images/shapes/banner_bg.png";
import step1 from "../assets/images/step1.png";
import step2 from "../assets/images/step2.png";
import step3 from "../assets/images/step3.png";
import contactform from "../assets/images/shapes/contact-form.png";
import logo from "../assets/images/newimgs/logo.png";
import Sidebar from "../Components/Sidebar";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";
import Whatsapp from "../Components/Whatsapp";
import "../assets/css/style.css";
import Loader from "react-js-loader";
import { NavLink, useNavigate } from "react-router-dom";
import img1 from "../assets/images/blog/img1.jpg";
import author from "../assets/images/blog/author-1.jpg";
import loading from "../assets/images/loading.gif";
import sateesh from "../assets/images/team/sateeshpunna.jpg";
import sharth from "../assets/images/team/sharth.png";
import gopi from "../assets/images/team/gopi.jfif";
import tiwari from "../assets/images/team/tiwari.jfif";
import swami from "../assets/images/team/swami.jfif";
import vijay from "../assets/images/team/vijay.jfif";
import mahesh from "../assets/images/team/mahesh.jpg";
import veket from "../assets/images/team/veket.jfif";
import ashif from "../assets/images/team/ashif.jfif";
import shiva from "../assets/images/team/shiva.jfif";
import mani from "../assets/images/team/mani.jfif";
import shanker from "../assets/images/team/shanker.jfif";
import bala from "../assets/images/team/bala.jfif";
import hr from "../assets/images/team/hr.jfif";
import shyam from "../assets/images/team/shyam.jfif";
import deepthi from "../assets/images/team/deepthi.png";
import syed from "../assets/images/team/syed.jpg";
import sharthchander from "../assets/images/team/sharthchander.png";
import suman from "../assets/images/team/sumansir.jfif";
import ganapathi from "../assets/images/team/imgaes/ganapathi.jpg";
import sravani from "../assets/images/team/sravani.jpg";
import AddingNew from "./AddingNew";
import { Helmet } from "react-helmet";

function Main() {
  const navigate = useNavigate();
  const textInput = React.useRef();
  const textInput1 = React.useRef();
  const textInput2 = React.useRef();
  const textInput3 = React.useRef();
  const [isLoading, setIsLoading] = useState(true);
  const [spinner, setSpinner] = useState(false);
  const [show, setShow] = useState(false);
  const [form, setform] = useState({
    name: "",
    phone: "",
    email: "",
    service: "Mobile Application",
  });
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [data, setdata] = useState([]);

  const [data1, setdata1] = useState([]);

  const [data2, setdata2] = useState([]);

  const [data3, setdata3] = useState([]);

  const [data4, setdata4] = useState([]);

  const [data5, setdata5] = useState([]);

  const [data6, setdata6] = useState([]);

  const [data7, setdata7] = useState([]);

  const [data8, setdata8] = useState([]);

  const [data9, setdata9] = useState([]);

  const [data10, setdata10] = useState([]);

  const [data11, setdata11] = useState([]);

  const [data12, setdata12] = useState([]);

  const [data15, setdata15] = useState([]);
  const [data16, setdata16] = useState([]);

  const [data17, setdata17] = useState([]);

  const [data18, setdata18] = useState([]);
  const [data19, setdata19] = useState([]);
  const [data20, setdata20] = useState([]);
  const [data13, setdata13] = useState([]);
  const [data14, setdata14] = useState([]);

  useEffect(() => {
    datas();
    loadimg();
  }, []);

  const api_url = "https://api.digitalraiz.co.in";

  const datas = () => {
    axios
      .post("https://api.digitalraiz.co.in/api/web/landing/getitems", {}, {})
      .then(
        (res) => {
          if (res.status === 200) {
            setIsLoading(false);
            setdata(res.data.HeadContentResult[0]);

            setdata1(res.data.footPrintResult[0]);

            setdata2(res.data.serviceHeadingResult[0]);
            setdata3(res.data.serviceResult);

            setdata4(res.data.creativityResult[0]);

            setdata5(res.data.technologyHeadingResult[0]);
            setdata6(res.data.technologyResult);

            setdata7(res.data.clientHeadResult[0]);
            setdata8(res.data.clientImageResult);

            setdata15(res.data.valuableResult[0]);
            setdata16(res.data.certificateResult);

            setdata9(res.data.qualityWorkResult[0]);
            setdata10(res.data.testimonialResult);

            setdata11(res.data.hireDevResult[0]);
            setdata12(res.data.industrySolutionResult[0]);

            setdata13(res.data.portfolioHeadingResult[0]);
            setdata14(res.data.portfolioMobileResult);
            setdata19(res.data.portfolioMarketResult);
            setdata20(res.data.portfoliowebResult);
            setdata16(res.data.certificateResult);
            setdata17(res.data.ratingResult);
            setdata18(res.data.contactInfoResult[0]);
          }
        },
        (error) => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message);
          }
        }
      );
  };

  const settings = {
    dots: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 2000,
    pauseOnFocus: false,
    pauseOnHover: false,
    pauseOnDotsHover: false,
    slidesToShow: 5,
    slidesToScroll: 1,
    arrows: false,
    width: 500,

    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
    ],
  };

  const settings2 = {
    dots: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 1500,
    pauseOnFocus: false,
    pauseOnHover: false,
    pauseOnDotsHover: false,
    slidesToShow: 6,
    slidesToScroll: 1,
    arrows: false,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
    ],
  };

  const settings5 = {
    dots: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 1500,
    pauseOnFocus: false,
    pauseOnHover: false,
    pauseOnDotsHover: false,
    slidesToShow: 6,
    slidesToScroll: 1,
    arrows: false,
    width: 500,
    rtl: true,
    height: 500,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
    ],
  };

  const settings1 = {
    dots: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 4500,
    pauseOnFocus: false,
    pauseOnHover: false,
    pauseOnDotsHover: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    width: 500,

    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
    ],
  };

  const settings3 = {
    dots: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 2000,
    pauseOnFocus: false,
    pauseOnHover: false,
    pauseOnDotsHover: false,
    slidesToShow: 4,
    slidesToScroll: 1,
    arrows: false,
    width: 500,

    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
    ],
  };

  const clearInput = () => {
    textInput.current.value = "";
    textInput1.current.value = "";
    textInput2.current.value = "";
    textInput3.current.value = "";
  };

  const handlechange = (e) => {
    let myUser = { ...form };
    myUser[e.target.name] = e.target.value;
    setform(myUser);
  };

  const addUsers = () => {
    var params = {
      name: form.name,
      phone: form.phone,
      email: form.email,
      service: form.service,
    };
    axios
      .post("https://api.digitalraiz.co.in/api/user/leads/addLead", params, {})
      .then((res) => {
        console.log("success");

        // userdata();
        handleClose();
        navigate("/thankyou");
      });
  };

  const usersSubmit = (e) => {
    e.preventDefault();
    addUsers();
    clearInput();
  };

  const loadimg = () => {
    setSpinner(true);
    setTimeout(() => {
      setSpinner(false);
    }, 40000);
  };

  return (
    <>
      <Helmet>
        <title>Best Software Company in Hyderabad – DigitalRaiz</title>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="theme-color" content="#000000" />

        <meta
          name="description"
          content="DigitalRaiz is one of the best Leading Software Company in India for Best Digital Marketing, Website Development & Mobile Application Development."
        />
        <meta
          name="keywords"
          content="Best software company in Hyderabad, best software company near by me, mobile app development company in Hyderabad, mobile app development company near by me, Flutter app development company in hyderabad, Flutter app development company in near me, Node Js Developers, Node js and react js developers, website design and development company in Hyderabad, website design and development company near by me, Best SEO company in hyderabad, Best SEO company near by me, best digital marketing company in hyderabad, best digital marketing company near by me, website design company, website design company in hyderabad, website design company in near me, Best digital marketing agency in hyderabad, Best digital marketing agency near me, Digital marketing company near me, Mobile application company, application development company in Hyderabad"
        />

        {/* Google Analytics script */}
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=G-001G7K6SKL"
        ></script>
        <script>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag() {
              dataLayer.push(arguments);
            }
            gtag("js", new Date());
            gtag("config", "G-001G7K6SKL");
          `}
        </script>
      </Helmet>

      <div style={{ overflow: "hidden" }}>
        {isLoading == true ? (
          <>
            <div
              style={{ zIndex: "9999999999999", height: "420px" }}
              className="text-center mt-3 mb-5"
            >
              <img src={loading} height="140px"></img>
            </div>
          </>
        ) : (
          <>
            <Header />
            <Sidebar />
            <Whatsapp />

            <section className="start_home demo3">
              <div className="home-top-shape">
                <img src={bannerbg} />
              </div>
              <div className="bubbles-animate">
                <div className="bubble b_one" />
                <div className="bubble b_two" />
                <div className="bubble b_three" />
                <div className="bubble b_four" />
                <div className="bubble b_five" />
                <div className="bubble b_six" />
              </div>
              <div className="banner_top">
                <div className="container">
                  <div className="row">
                    <div className="col-md-6 start-home-content bannerh1text">
                      <h1
                        data-wow-delay="400ms"
                        data-wow-duration="3000ms"
                        data-aos="fade-right"
                      >
                        {data.title}
                      </h1>
                      <p
                        className="bannertet"
                        data-wow-delay="900ms"
                        data-wow-duration="3000ms"
                        data-aos="fade-left"
                      >
                        {data.description}
                      </p>

                      <div className="banner-btns">
                        <div className="row">
                          <div className="col">
                            <a
                              className="btn theme-btn d-inline-block"
                              onClick={handleShow}
                              data-wow-delay="1500ms"
                              data-wow-duration="2000ms"
                              data-aos="fade-up"
                            >
                              Talk to Our Expert{" "}
                              <i
                                class="fa fa-arrow-right"
                                aria-hidden="true"
                              ></i>
                            </a>
                          </div>
                          <div className="col">
                            <div
                              data-wow-delay="1500ms"
                              data-wow-duration="2000ms"
                              data-aos="fade-up"
                            >
                              <img
                                src={api_url + "/" + data.ceoImage}
                                className="mt-3 ceoimg"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-6 start-home-img">
                      <img
                        className="img-fluid"
                        src={api_url + "/" + data.mainImage}
                        alt="Img"
                        data-wow-delay="900ms"
                        data-wow-duration="3000ms"
                        data-aos="fade-left"
                      />
                    </div>

                    <div className="banner-btns ceodsk">
                      <div className="row">
                        <div className="col">
                          <a
                            className="btn theme-btn d-inline-block"
                            onClick={handleShow}
                            data-wow-delay="1500ms"
                            data-wow-duration="2000ms"
                            data-aos="fade-up"
                          >
                            Talk to Our Expert{" "}
                            <i class="fa fa-arrow-right" aria-hidden="true"></i>
                          </a>
                        </div>
                        <div className="col">
                          <div
                            data-wow-delay="1500ms"
                            data-wow-duration="2000ms"
                            data-aos="fade-up"
                          >
                            <img
                              src={api_url + "/" + data.ceoImage}
                              className="mt-3 ceoimg"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <section>
              <div className="container">
                <div className="section-header">
                  <h2>{data1.title}</h2>
                  <p>{data1.description}</p>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div class="contact-shape">
                      <img src={contactform} class="img-fluid" alt="Img" />
                    </div>
                    <div
                      class="contact-form-block"
                      data-wow-delay="1500ms"
                      data-wow-duration="2000ms"
                      data-aos="fade-up"
                    >
                      <div class="section-header-style2"></div>
                      <form class="contact-form" onSubmit={usersSubmit}>
                        <input
                          type="text"
                          pattern="^[\w'\-,.][^0-9_!¡?÷?¿/\\+=@#$%ˆ&*(){}|~<>;:[\]]{2,}$"
                          className="form-control"
                          name="name"
                          ref={textInput}
                          onChange={(e) => {
                            handlechange(e);
                          }}
                          id="signinEmail"
                          placeholder="Full Name"
                          aria-label="Email address"
                          required
                        />

                        <input
                          type="text"
                          maxlength="10"
                          pattern="\d{10}"
                          className="form-control"
                          name="phone"
                          ref={textInput1}
                          id="signinPassword"
                          onChange={(e) => {
                            handlechange(e);
                          }}
                          placeholder="Mobile No..."
                          aria-label="Password"
                          required
                        />

                        <input
                          type="email"
                          pattern="(^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$)"
                          className="form-control"
                          name="email"
                          ref={textInput2}
                          id="signinPassword2"
                          onChange={(e) => {
                            handlechange(e);
                          }}
                          placeholder="Email Id"
                          aria-label="Password"
                          required
                        />
                        <select
                          className="form-control"
                          name="service"
                          ref={textInput3}
                          id="signinPassword2"
                          onChange={(e) => {
                            handlechange(e);
                          }}
                          required
                        >
                          <option value="">Services</option>
                          <option
                            className="form-control"
                            value="Mobile Application"
                          >
                            Mobile Application
                          </option>
                          <option
                            className="form-control"
                            value="Website Design & Development"
                          >
                            Website Design & Development
                          </option>
                          <option
                            className="form-control"
                            cvalue="Digital Marketing"
                          >
                            Digital Marketing
                          </option>
                          <option className="form-control" cvalue="Others">
                            Others
                          </option>
                        </select>
                        <button class="btn theme-btn" type="submit">
                          Submit
                        </button>
                      </form>
                    </div>
                  </div>
                  <div className="col-md-6" style={{ padding: "10px" }}>
                    <div
                      className="description-block"
                      data-wow-delay="1000ms"
                      data-wow-duration="2000ms"
                      data-aos="fade-up"
                    >
                      <div className="inner-box">
                        <div className="step_num">
                          <img src={step1} />
                        </div>

                        <h3>{data1.subtitleOne}</h3>
                        <p>{data1.subDescriptionOne}</p>
                      </div>
                    </div>

                    <div
                      className="description-block"
                      data-wow-delay="2000ms"
                      data-wow-duration="3000ms"
                      data-aos="fade-up"
                    >
                      <div className="inner-box">
                        <div className="step_num">
                          <img src={step2} />
                        </div>

                        <h3>{data1.subtitleTwo}</h3>
                        <p>{data1.subDescriptionTwo}</p>
                      </div>
                    </div>

                    <div
                      className="description-block"
                      data-wow-delay="3000ms"
                      data-wow-duration="4000ms"
                      data-aos="fade-up"
                    >
                      <div className="inner-box">
                        <div className="step_num">
                          <img src={step3} />
                        </div>

                        <h3>{data1.subtitleThree}</h3>
                        <p>{data1.subDescriptionThree}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <section
              id="how-it-work"
              class="section-block"
              data-scroll-index="2"
            >
              <div className="container">
                <div className="sectionhead">
                  <h2 className="serviceh2">{data2.title}</h2>
                  <p className="col-10 offset-1 text-center ">
                    {data2.description}
                  </p>
                </div>

                <div className="home-banner-blocks">
                  <div className="container">
                    <div className="row">
                      {data3.map((data, i) => {
                        return (
                          <>
                            {" "}
                            <div className="col-md-4">
                              <a
                                className="banner-blk cardsheight"
                                data-wow-delay="3000ms"
                                data-wow-duration="4000ms"
                                data-aos="fade-up"
                              >
                                <div className="bnr-icon">
                                  <img
                                    src={api_url + "/" + data.image}
                                    alt="img"
                                  />
                                </div>
                                <h3>{data.title}</h3>

                                <p>{data.description}</p>
                                <a
                                  className="btn theme-btn d-inline-block"
                                  target="_blank"
                                  href={data.link}
                                >
                                  Read More
                                  <i
                                    class="fa fa-arrow-right ml-1"
                                    aria-hidden="true"
                                  ></i>
                                </a>
                              </a>
                            </div>{" "}
                          </>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <section className="section-block">
              <div className="section-header">
                <h2>{data4.title}</h2>
                <p className="col-10 offset-1 text-center">
                  {data4.description}
                </p>
              </div>
              <div className="container">
                <div className="row">
                  <div className="col-md-6">
                    <div className="section-header-style2 mt-5">
                      <h5
                        data-wow-delay="1000ms"
                        data-wow-duration="3000ms"
                        data-aos="fade-right"
                      >
                        {data4.subtitle}
                      </h5>

                      <p
                        className="mobapp mt-4"
                        dangerouslySetInnerHTML={{
                          __html: data4.points,
                        }}
                      />
                      <div className="review_nav"></div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="img-box">
                      <img
                        src={api_url + "/" + data4.image}
                        className="img-fluid"
                        id="ds"
                        alt="Img"
                      />
                    </div>
                  </div>
                </div>
                <div></div>
              </div>
            </section>

            <div>
              <div className="section-header">
                <h2>{data5.title}</h2>
                <div className="row">
                  <p className="col-10 offset-1 text-center">
                    {data5.description}
                  </p>
                </div>
              </div>

              <div className="container">
                `{" "}
                <div className="owl-carousel list-clients">
                  `
                  <Slider {...settings}>
                    {data6.map((data, i) => {
                      return (
                        <>
                          <div className="clients-item">
                            <a title>
                              <img
                                key={i}
                                src={api_url + "/" + data.image}
                                alt="Img"
                              />
                            </a>
                          </div>
                        </>
                      );
                    })}
                  </Slider>
                </div>
              </div>
            </div>

            <section data-scroll-index="2">
              <div style={{ marginTop: "30px" }}>
                <div className="section-header">
                  <h2>{data7.title}</h2>
                  <div className="row">
                    <p className="col-10 offset-1 text-center">
                      {data7.description}
                    </p>
                  </div>
                </div>

                <div className="container">
                  <div className="owl-carousel list-clients">
                    <Slider {...settings2}>
                      {data8.map((data, i) => {
                        return (
                          <>
                            <div className="item" key={i}>
                              <div
                                style={{
                                  border: "1px solid  #c2b8b8",
                                  marginLeft: "1px",
                                }}
                              >
                                <img
                                  style={{
                                    padding: "5px",
                                    width: "150px",
                                    height: "120px",
                                  }}
                                  src={api_url + "/" + data.image}
                                  alt="Img"
                                  id="slid1"
                                />
                              </div>
                            </div>
                          </>
                        );
                      })}
                    </Slider>
                  </div>
                  <div className="owl-carousel list-clients">
                    <Slider {...settings5}>
                      {data8.map((data, i) => {
                        return (
                          <>
                            <div className="item" key={i}>
                              <div
                                style={{
                                  border: "1px solid #c2b8b8",
                                  marginLeft: "1px",
                                }}
                              >
                                <img
                                  style={{
                                    padding: "5px",
                                    width: "150px",
                                    height: "120px",
                                  }}
                                  src={api_url + "/" + data.image}
                                  alt="Img"
                                  id="slid1"
                                />
                              </div>
                            </div>
                          </>
                        );
                      })}
                    </Slider>
                  </div>
                </div>
              </div>
            </section>

            <div className="mt-5">
              <div class="cardst">
                <div className="section-header mt-3">
                  <h2>{data15.title}</h2>
                  <div className="row">
                    <p className="col-10 offset-1 text-center">
                      {data15.description}
                    </p>
                  </div>
                </div>
                <div class="container">
                  <div className="row">
                    <h5 className="ceo mb-3">Contact : +91 9494613601</h5>
                  </div>
                  <div className="container">
                    <div className="row ">
                      {data16.map((data, i) => {
                        return (
                          <>
                            <div class="col-sm ml-4" key={i}>
                              <img src={api_url + "/" + data.image} />
                            </div>
                          </>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <section id="reviews" className="qualityse " data-scroll-index={7}>
              <div className="shape-top" />
              <div className="container">
                <div className="section-header">
                  <h2>{data9.title}</h2>
                </div>

                <div className="row">
                  <div className="col-md-5">
                    <div className="section-header-style2 mb-5">
                      <h2
                        data-wow-delay="5000ms"
                        data-wow-duration="5000ms"
                        data-aos="fade-right"
                      >
                        {data9.subtitle}
                      </h2>
                      <p
                        data-wow-delay="5000ms"
                        data-wow-duration="5000ms"
                        data-aos="fade-left"
                      >
                        {data9.description}
                      </p>
                      <div className="btn-read-more">
                        <a
                          className="btn theme-btn mb-5"
                          onClick={handleShow}
                          data-wow-delay="1500ms"
                          data-wow-duration="2000ms"
                          data-aos="fade-up"
                        >
                          Get Quote{" "}
                          <i class="fa fa-arrow-right" aria-hidden="true"></i>
                        </a>{" "}
                      </div>

                      <div className="review_nav"></div>
                    </div>
                  </div>
                  <div className="col-md-7">
                    <div className="review-details-content">
                      <div
                        className="owl-carousel review_details"
                        id="review_details-1"
                      >
                        <Slider {...settings1}>
                          {data10.map((data, i) => {
                            return (
                              <>
                                <div
                                  className="item"
                                  style={{ marginTop: "60px" }}
                                >
                                  <p>{data.description}</p>
                                  <h5>{data.keyperson}</h5>
                                  <h6>{data.designation}</h6>
                                </div>
                              </>
                            );
                          })}
                        </Slider>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="review-shape-bottom" />
              <div className="shape-bottom" />
            </section>

            <section className="section-block" data-scroll-index={6}>
              <div className="container">
                <div className="row">
                  <div className="col-md-6">
                    <div className="section-header-style2">
                      <h2
                        data-wow-delay="1000ms"
                        data-wow-duration="3000ms"
                        data-aos="fade-right"
                      >
                        {data11.title}
                      </h2>
                      <p
                        data-wow-delay="1000ms"
                        data-wow-duration="3000ms"
                        data-aos="fade-left"
                      >
                        {data11.description}
                      </p>
                      <div className="review_nav"></div>
                      <div className="btn-read-more">
                        <a
                          className="btn theme-btn"
                          onClick={handleShow}
                          data-wow-delay="1500ms"
                          data-wow-duration="2000ms"
                          data-aos="fade-up"
                        >
                          Start Your project{" "}
                          <i class="fa fa-arrow-right" aria-hidden="true"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="img-box">
                      <img
                        src={api_url + "/" + data11.image}
                        className="img-fluid"
                        alt="Img"
                      />
                    </div>
                  </div>
                </div>
                <div></div>
              </div>
            </section>

            <section
              id="faqs"
              className="section-block mt-5"
              data-scroll-index={6}
            >
              <div className="container">
                <div className="row">
                  <div className="col-md-6">
                    <div className="">
                      <img
                        src={suman}
                        style={{ height: "320px", width: "80%" }}
                        className="img-fluid rounded"
                        alt="Img"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="section-header-style2 mt-5 pt-5">
                      <h2
                        data-wow-delay="1000ms"
                        data-wow-duration="3000ms"
                        data-aos="fade-right"
                      >
                        Mr. Suman Akula- Director
                      </h2>
                      {/* <p
                        data-wow-delay="1000ms"
                        data-wow-duration="3000ms"
                        data-aos="fade-left"
                      >
                        Director
                      </p> */}
                      <p className="mt-3">
                        Mr. SUMAN AKULA - DigitalRaiz Creative Solutions Pvt.
                        Ltd. He is a good vision for software industry
                        innovation, is the driving force behind DigitalRaiz
                        Creative Solutions Pvt. Ltd.'s growth and innovation,
                        propelling the company to new heights.
                      </p>
                    </div>
                  </div>
                </div>
                <div></div>
              </div>
            </section>

            {/* 
            <section id="faqs" className="section-block mt-5" data-scroll-index={6}>
              <div className="container">
                <div className="row">
                  <div className="col-md-6">
                    <div className="section-header-style2 mt-5 pt-5">
                      <h2
                        data-wow-delay="1000ms"
                        data-wow-duration="3000ms"
                        data-aos="fade-right"
                      >
                        Mrs. Sravani Akula-   Director
                      </h2>
                      <p className="mt-3">
                        Mrs. SRAVANI AKULA - DigitalRaiz Creative Solutions Pvt. Ltd.
                        She her a dynamic leader at DigitalRaiz Creative Solutions Pvt. Ltd, plays an instrumental role in the company's success through her operational excellence and strategic insights.
                      </p>

                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="">
                      <img
                        src={sravani}
                        style={{ height: "400px", width: "90%" }}
                        className="img-fluid rounded"
                        alt="Img"
                      />
                    </div>
                  </div>


                </div>
                <div></div>
              </div>
            </section> */}

            {/* <section id="blog" className="section-block mt-5" data-scroll-index={5}>
              <div className="container">
                <div className="section-header">
                  <h2>Our Team</h2>
                  <p style={{ fontSize: '15px' }}>
                    
                    Our dedicated team at DigitalRaiz creative solutions  Pvt .Ltd . Combines   Talent , Innovation and Expertise to craft cutting-edge solutions for your digital needs.
                  </p>
                </div>
                <div className="owl-carousel blog-slider">
                  <Slider {...settings3}>

                    <div className="blog-item">
                      <div className="blog-article style-1 m-2">
                        <div className="article-img">
                          <img style={{ height: "300px" }} src={bala} className="imgfluid" alt="Img" />
                        </div>

                      </div>
                      <div className="content" id="jd">
                        <h4>B.Balakrishna</h4>
                        <p>Project Manager</p>
                      </div>
                    </div>

                    <div class="blog-item">
                      <div class="blog-article style-1 m-2">
                        <div class="article-img">
                          <img style={{ height: "300px" }} src={hr} className="img-fluid" alt="Img" />
                        </div>
                      </div>
                      <div className="content" id="jd">
                        <h4>Deepthi Dondapati</h4>
                        <p >Human Resources</p>
                      </div>
                    </div>

                    <div class="blog-item">
                      <div class="blog-article style-1 m-2">
                        <div class="article-img">
                          <img src={tiwari} style={{ height: "300px" }} className="img-fluid" alt="Img" />
                        </div>
                      </div>
                      <div className="content" id="jd">
                        <h4>Srikanth Tiwari</h4>
                        <p>Lead Flutter Developer</p>
                      </div>
                    </div>

                    <div class="blog-item">
                      <div class="blog-article style-1 m-2">
                        <div class="article-img">
                          <img src={ashif} style={{ height: "300px" }} className="img-fluid" alt="Img" />
                        </div>
                        
                      </div>
                      <div className="content" id="jd">
                        <h4>Asif Shaik</h4>
                        <p>Flutter Developer</p>
                      </div>
                    </div>

                    <div class="blog-item">
                      <div class="blog-article style-1 m-2">
                        <div class="article-img">
                          <img src={gopi} style={{ height: "300px" }} className="img-fluid" alt="Img" />
                        </div>
                       
                      </div>
                      <div className="content" id="jd">
                        <h4>Vemala Gopi</h4>
                        <p>Flutter Developer</p>
                      </div>
                    </div>
                    <div class="blog-item">
                      <div class="blog-article style-1 m-2">
                        <div class="article-img">
                          <img src={swami} style={{ height: "300px" }} className="img-fluid" alt="Img" />
                        </div>
                       
                      </div>
                      <div className="content" id="jd">
                        <h4>Vanimina Swamy </h4>
                        <p>Flutter Developer</p>
                      </div>
                    </div>

                    <div class="blog-item">
                      <div class="blog-article style-1 m-2">
                        <div class="article-img">
                          <img style={{ height: "300px" }} src={sateesh} className="img-fluid" alt="Img" />
                        </div>
                       
                      </div>
                      <div className="content" id="jd">
                        <h4>Sateesh Punna</h4>
                        <p>React JS Developer</p>
                      </div>
                    </div>
                    <div class="blog-item">
                      <div class="blog-article style-1 m-2">
                        <div class="article-img">
                          <img style={{ height: "300px" }} src={shanker} className="img-fluid" alt="Img" />
                        </div>
                        
                      </div>
                      <div className="content" id="jd">
                        <h4>Senkar rao</h4>
                        <p>React JS Developer</p>
                      </div>
                    </div>
                    <div class="blog-item">
                      <div class="blog-article style-1 m-2">
                        <div class="article-img">
                          <img style={{ height: "300px" }} src={deepthi} className="img-fluid" alt="Img" />
                        </div>
                       
                      </div>
                      <div className="content" id="jd">
                        <h4>Lakshmideepthi</h4>
                        <p>React JS Developer</p>
                      </div>
                    </div>
                    <div class="blog-item">
                      <div class="blog-article style-1 m-2">
                        <div class="article-img">
                          <img style={{ height: "300px" }} src={syed} className="img-fluid" alt="Img" />
                        </div>
                    
                      </div>
                      <div className="content" id="jd">
                        <h4>Syed</h4>
                        <p>Node Js Developer</p>
                      </div>
                    </div>
                    <div class="blog-item">
                      <div class="blog-article style-1 m-2">
                        <div class="article-img">
                          <img src={sharthchander} style={{ height: "300px" }} className="img-fluid" alt="Img" />
                        </div>
                        
                      </div>
                      <div className="content" id="jd">
                        <h4>Sharath Chander</h4>
                        <p>Node Js Developer</p>
                      </div>
                    </div>

                    <div class="blog-item">
                      <div class="blog-article style-1 m-2">
                        <div class="article-img">
                          <img src={shiva} style={{ height: "300px" }} className="img-fluid" alt="Img" />
                        </div>
                       
                      </div>
                      <div className="content" id="jd">
                        <h4>Shiva Prasad Etika</h4>
                        <p>Node Js Developer</p>
                      </div>
                    </div>
                    <div class="blog-item">
                      <div class="blog-article style-1 m-2">
                        <div class="article-img">
                          <img src={vijay} style={{ height: "300px" }} className="img-fluid" alt="Img" />
                        </div>
                      
                      </div>
                      <div className="content" id="jd">
                        <h4>Ch. Vijaya Durga</h4>
                        <p>QA Tester</p>
                      </div>
                    </div>
                    <div class="blog-item">
                      <div class="blog-article style-1 m-2">
                        <div class="article-img">
                          <img src={mani} style={{ height: "300px" }} className="img-fluid" alt="Img" />
                        </div>
                       
                      </div>
                      <div className="content" id="jd">
                        <h4>Mani Kiran Katepalli</h4>
                        <p>QA Tester</p>
                      </div>
                    </div>

                    <div class="blog-item">
                      <div class="blog-article style-1 m-2">
                        <div class="article-img">
                          <img style={{ height: "300px" }} src={sharth} className="img-fluid" alt="Img" />
                        </div>
                        
                      </div>
                      <div className="content" id="jd">
                        <h4>P. Sharath Kumar</h4>
                        <p>Digital Marketing Team Leader</p>
                      </div>
                    </div>

                    <div class="blog-item">
                      <div class="blog-article style-1 m-2">
                        <div class="article-img">
                          <img src={mahesh} style={{ height: "300px" }} className="img-fluid" alt="Img" />
                        </div>
                        
                      </div>
                      <div className="content" id="jd">
                        <h4>Y. Ganga Mahesh</h4>
                        <p>Digital Marketing Executive </p>
                      </div>
                    </div>

                    <div class="blog-item">
                      <div class="blog-article style-1 m-2">
                        <div class="article-img">
                          <img src={veket} style={{ height: "300px" }} className="img-fluid" alt="Img" />
                        </div>
                       
                      </div>
                      <div className="content" id="jd">
                        <h4>U. Venketa Raju Gupta</h4>
                        <p>Graphic Designer</p>
                      </div>
                    </div>

                    <div class="blog-item">
                      <div class="blog-article style-1 m-2">
                        <div class="article-img">
                          <img src={ganapathi} style={{ height: "300px" }} className="img-fluid" alt="Img" />
                        </div>
                       
                      </div>
                      <div className="content" id="jd">
                        <h4>Ganapathi Varma</h4>
                        <p>UI / UX Designer</p>
                      </div>
                    </div>

                  </Slider>
                </div>
              </div>
            </section> */}

            <div className="section-header mt-5 indsolbg">
              <h3>{data12.title}</h3>
              <p>{data12.description}</p>
              <div className="d-flex justify-content-center mt-3">
                {/* <button
                  className="btn themes-btn btn-block indsolbtns mt-2 mr-3"
                  onClick={handleShow}
                >
                  <a className="btna">
                    {" "}
                    <i class="fa fa-user-circle-o mr-1" aria-hidden="true"></i>Hire
                    Our Experts
                  </a>
                </button> */}
                <button
                  className="btn theme-btn btn-block indsolbtns"
                  onClick={handleShow}
                >
                  <a className="btna text-white">
                    <i class="fa fa-phone mr-1" aria-hidden="true"></i>Talk to
                    Our Experts{" "}
                  </a>
                </button>
              </div>
            </div>

            {/* adding new section  */}

            <AddingNew />

            {/* ================================================================ */}
            <Creative
              data19={data19}
              data20={data20}
              data13={data13}
              data14={data14}
            />

            <Footer data16={data16} data17={data17} data18={data18} />

            <Modal
              size="lg"
              show={show}
              onHide={handleClose}
              backdrop="static"
              keyboard={false}
              className="mt-5"
            >
              <div className="row login-page">
                <div className="col-md-5 mobileslider cross">
                  <div className=" login-side-des">
                    <div className="container-fluid">
                      <div className="login-side-block">
                        <a href="">
                          <img src={logo} alt="Logo" />
                        </a>
                        <div className="login-reviews">
                          <div className="review-details-content">
                            <div
                              className="owl-carousel review_details"
                              id="review_details-2"
                            >
                              <Slider {...settings1}>
                                {data10.map((data, i) => {
                                  return (
                                    <>
                                      <div className="item">
                                        <p>{data.description}</p>
                                        <h5>{data.keyperson}</h5>
                                        <h6>{data.designation}</h6>
                                      </div>
                                    </>
                                  );
                                })}
                              </Slider>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-7">
                  <div className="container">
                    <a href="#" className="res-logo">
                      <img src={logo} alt="Logo" />
                    </a>
                    <div className="login-form modheight">
                      <div className="login-form-head ">
                        <h2>
                          <span className="h2txt">Welcome to</span>{" "}
                          <span>DigitalRaiz</span>
                        </h2>
                        <p>Fill out the form to get started..</p>
                      </div>
                      <form onSubmit={usersSubmit}>
                        <div className="form-group">
                          <label className="form-label" htmlFor="signinEmail">
                            Full Name
                          </label>
                          <div className="input-group">
                            <div className="input-icon">
                              <span className="fa fa-user-o" />
                            </div>
                            <input
                              type="text"
                              pattern="^[\w'\-,.][^0-9_!¡?÷?¿/\\+=@#$%ˆ&*(){}|~<>;:[\]]{2,}$"
                              className="form-control"
                              name="name"
                              onChange={(e) => {
                                handlechange(e);
                              }}
                              id="signinEmail"
                              placeholder="Full Name"
                              aria-label="Email address"
                              required
                            />
                          </div>
                        </div>
                        <div className="form-group">
                          <label
                            className="form-label"
                            htmlFor="signinPassword"
                          >
                            Mobile
                          </label>
                          <div className="input-group">
                            <div className="input-icon">
                              <span className="fa fa-mobile" />
                            </div>
                            <input
                              type="text"
                              minlength="0"
                              maxlength="10"
                              pattern="\d{10}"
                              className="form-control"
                              name="phone"
                              id="signinPassword"
                              onChange={(e) => {
                                handlechange(e);
                              }}
                              placeholder="Mobile No..."
                              aria-label="Password"
                              required
                            />
                          </div>
                        </div>

                        <div className="form-group">
                          <label
                            className="form-label"
                            htmlFor="signinPassword2"
                          >
                            Services
                          </label>
                          <div className="input-group">
                            <div className="input-icon">
                              <span className="fa fa-globe" />
                            </div>
                            <select
                              className="form-control"
                              value={form.service}
                              name="service"
                              id="signinPassword2"
                              placeholder="Email Id"
                              onChange={(e) => {
                                handlechange(e);
                              }}
                              aria-label="Password"
                              required
                            >
                              <option value="">Select</option>
                              <option value="Mobile Application">
                                Mobile Application
                              </option>
                              <option
                                className="form-control"
                                value="Website Design & Development"
                              >
                                Website Design & Development
                              </option>
                              <option
                                className="form-control"
                                cvalue="Digital Marketing"
                              >
                                Digital Marketing
                              </option>
                              <option className="form-control" cvalue="Others">
                                Others
                              </option>
                            </select>
                          </div>
                        </div>
                        <div className="form-group"></div>
                        <div className="form-group">
                          <button
                            className="btn theme-btn btn-block"
                            type="submit"
                          >
                            Submit
                          </button>
                        </div>
                      </form>
                      <p class="visible" onClick={handleClose}>
                        .
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </Modal>
          </>
        )}

        <Toaster position="top-right" reverseOrder={false} />
      </div>
    </>
  );
}

export default Main;
