import * as React from "react";
import { styled, useTheme, alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import MailIcon from "@mui/icons-material/Mail";
import { Link, NavLink } from "react-router-dom";
import Drawerdata from "./Drawerdata";
// import "./zzz.css";
import "../Css/zzz.css";
import InputBase from "@mui/material/InputBase";
import Badge from "@mui/material/Badge";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import SearchIcon from "@mui/icons-material/Search";
import AccountCircle from "@mui/icons-material/AccountCircle";
import NotificationsIcon from "@mui/icons-material/Notifications";
import MoreIcon from "@mui/icons-material/MoreVert";
import { Divider } from "@mui/material";
// import logo from "../assets/img/logo/logo.png";
// import logo from "../assets/images/logo_IGI.png";
// import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router";
import PersonIcon from "@mui/icons-material/Person";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import logo2 from "../assets/images/newimgs/logo.png";

// dashboard icons

// import DashboardIcon from "@mui/icons-material/Dashboard";
import DashboardIcon from "@mui/icons-material/Dashboard";
import ViewCarouselIcon from "@mui/icons-material/ViewCarousel";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import VideoSettingsIcon from "@mui/icons-material/VideoSettings";
import SettingsIcon from "@mui/icons-material/Settings";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import GroupIcon from "@mui/icons-material/Group";
import NewspaperIcon from "@mui/icons-material/Newspaper";
import TempleHinduIcon from "@mui/icons-material/TempleHindu";
import LeakAddIcon from "@mui/icons-material/LeakAdd";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import VideoLibraryIcon from "@mui/icons-material/VideoLibrary";
import ArticleIcon from "@mui/icons-material/Article";
import CompassCalibrationIcon from "@mui/icons-material/CompassCalibration";
import AssignmentReturnIcon from "@mui/icons-material/AssignmentReturn";
import AddBusinessIcon from "@mui/icons-material/AddBusiness";
import DeviceHubIcon from "@mui/icons-material/DeviceHub";

const drawerWidth = 220;

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
}));

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "auto",
  },
}));

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
  // background: "rgb(250,236,197)",
  background: "#fff",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(8)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
  // background: "rgb(250,236,197)",
  background: "#fff",
  // background:"#e9e9e9",
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
  // background:"#e9e9e9",
}));

export default function MiniDrawer() {
  let navigate = useNavigate();

  const handlesession = () => {
    sessionStorage.clear();
    // localStorage.clear();
    navigate("/Login");
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  // function HeaderView() {
  //    const location = useLocation();
  //   return (location.pathname)
  // }

  const menuId = "primary-search-account-menu";
  const renderMenu = (
    <Menu
      style={{ marginTop: "50px" }}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <NavLink to="/Profile">
        <MenuItem onClick={handleMenuClose}>
          <div className="row" style={{ width: "100%" }}>
            <div className="col mr-5 pcard" style={{ width: "60%" }}>
              {" "}
              Profile
            </div>
            <div className="col pcard">
              <div style={{ float: "right" }}>
                <PersonIcon />
              </div>
            </div>
          </div>
        </MenuItem>
      </NavLink>
      <MenuItem onClick={handlesession}>
        <div className="row mt-2" style={{ marginBottom: "-10px" }}>
          <div className="col" style={{ width: "60%" }}>
            {" "}
            <p style={{ width: "125px" }}>Log out</p>
          </div>
          <div className="col">
            <ExitToAppIcon />
          </div>
        </div>
      </MenuItem>
    </Menu>
  );

  const mobileMenuId = "primary-search-account-menu-mobile";
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      {/* <MenuItem  >
        <IconButton size="large" aria-label="show 4 new mails" color="inherit">
          <Badge badgeContent={4} color="error">
            <MailIcon />
          </Badge>
        </IconButton>
        <p>Messages</p>
      </MenuItem> */}
      {/* <MenuItem>
        <IconButton size="large" color="inherit">
          <Badge badgeContent={17} color="error">
            <NotificationsIcon />
          </Badge>
        </IconButton>
        <p>Notifications</p>
      </MenuItem> */}
      <MenuItem onClick={handleProfileMenuOpen}>
        <IconButton
          size="large"
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          <AccountCircle className="mb-3" />
        </IconButton>
        <NavLink to="/Profile">
          {" "}
          <p>Profile</p>
        </NavLink>
      </MenuItem>
    </Menu>
  );

  const theme = useTheme();
  const [open, setOpen] = React.useState(true);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        open={open}
        style={{
          background: "#fff",
          //  background: "rgb(250,236,197)",
          height: "65px",
        }}
      >
        <Toolbar style={{ color: "black" }}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: 5,
              ...(open && { display: "none" }),
            }}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{ display: { xs: "none", sm: "block" } }}
          >
            {" "}
            {/* <img src={logo} alt="logo" width={200} /> */}
            {/* <img src={logo}  width={80} alt="logo" /> */}
          </Typography>
          {/* <Search style={{ color: "white" }}>
            <SearchIconWrapper>
              <SearchIcon style={{ color: "black" }} />
            </SearchIconWrapper>
            <StyledInputBase
              style={{ color: "black" }}
              placeholder="Search…"
              inputProps={{ "aria-label": "search" }}
            />
          </Search> */}
          <Box sx={{ flexGrow: 1 }} />
          <Box sx={{ display: { xs: "none", md: "flex" } }}>
            {/* <IconButton
              size="large"
              aria-label="show 4 new mails"
              color="inherit"
              style={{ color: "black" }}
            >
              <Badge color="error"><MailIcon /></Badge>
            </IconButton> */}
            {/* <IconButton size="large" color="inherit">
              <Badge color="error">
                <NotificationsIcon style={{ color: "black" }} />
              </Badge>
            </IconButton> */}
            <IconButton
              size="large"
              edge="end"
              aria-label="account of current user"
              aria-controls={menuId}
              aria-haspopup="true"
              onClick={handleProfileMenuOpen}
              color="inherit"
            >
              <AccountCircle style={{ color: "black" }} />
            </IconButton>
          </Box>
          <Box sx={{ display: { xs: "flex", md: "none" } }}>
            <IconButton
              style={{ color: "black" }}
              size="large"
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="inherit"
            >
              <MoreIcon style={{ color: "black" }} />
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={open} style={{ background: "red" }}>
        <DrawerHeader
          style={{
            background: "#fff",
            //  background: "rgb(250,236,197)",
            height: "65px",
          }}
        >
          <NavLink to="/admin">
            <img src={logo2} alt="logo" />
          </NavLink>
          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{ display: { xs: "none", sm: "block" } }}
            style={{ color: "black", fontWeight: "bold", padding: "5px" }}
          ></Typography>
          {/* <img src={logo} alt="logo" width={150} /> */}
          <IconButton onClick={handleDrawerClose} style={{ color: "black" }}>
            {theme.direction === "rtl" ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </DrawerHeader>

        <Divider></Divider>
        <div className="DrawerData">
          <ul className="Drawerlist ">
            {/* <li className="active">
            {(sessionStorage.getItem('AdminId')!='' && sessionStorage.getItem('AdminId'))?
              <NavLink to="/admin" className="Drawer-a">
              <div className="Drawer-row">
                <span className="Drawericon"><DashboardIcon /></span>
                <a className="Drawertitle">Dashboard</a>
              </div>
            </NavLink>:''}
            </li>
            <li className="active">
            {(sessionStorage.getItem('AdminId')!='' && sessionStorage.getItem('AdminId'))?
              <NavLink to="/Addtemple1" className="Drawer-a">
              <div className="Drawer-row">
                <span className="Drawericon"><TempleHinduIcon /></span>
                <a className="Drawertitle">Temples</a>
              </div>
            </NavLink>:""}
            </li>
            <li className="active"><NavLink to="/News" className="Drawer-a">
              <div className="Drawer-row">
                <span className="Drawericon"><ArticleIcon /></span>
                <a className="Drawertitle">News</a>
              </div>
            </NavLink>
            </li>
            <li className="active"><NavLink to="/Gallery" className="Drawer-a">
              <div className="Drawer-row">
                <span className="Drawericon"><AddPhotoAlternateIcon /></span>
                <a className="Drawertitle">Gallery</a>
              </div>
            </NavLink>
            </li>
            <li className="active"><NavLink to="/Videos" className="Drawer-a">
              <div className="Drawer-row">
                <span className="Drawericon"><VideoLibraryIcon /></span>
                <a className="Drawertitle">Videos</a>
              </div>
            </NavLink>
            </li>
            <li className="active"><NavLink to="/Banner" className="Drawer-a">
              <div className="Drawer-row">
                <span className="Drawericon"><ViewCarouselIcon /></span>
                <a className="Drawertitle">Banner</a>
              </div>
            </NavLink>
            </li>
            <li className="active"><NavLink to="/Customers" className="Drawer-a">
              <div className="Drawer-row">
                <span className="Drawericon"><GroupIcon /></span>
                <a className="Drawertitle">Customers</a>
              </div>
            </NavLink>
            </li>
             <li className="active"><NavLink to="/Department" className="Drawer-a">
              <div className="Drawer-row">
                <span className="Drawericon"><DeviceHubIcon /></span>
                <a className="Drawertitle">Department</a>
              </div>
            </NavLink>
            </li>
             <li className="active"><NavLink to="/Users" className="Drawer-a">
              <div className="Drawer-row">
                <span className="Drawericon"><GroupAddIcon /></span>
                <a className="Drawertitle">Users</a>
              </div>
            </NavLink>
            </li> <li className="active"><NavLink to="/Testimonials" className="Drawer-a">
              <div className="Drawer-row">
                <span className="Drawericon"><AssignmentReturnIcon /></span>
                <a className="Drawertitle">Testimonials</a>
              </div>
            </NavLink>
            </li>
            <li className="active"><NavLink to="/Projects" className="Drawer-a">
              <div className="Drawer-row">
                <span className="Drawericon"><AccountTreeIcon /></span>
                <a className="Drawertitle">Projects</a>
              </div>
            </NavLink>
            </li>
            <li className="active"><NavLink to="/Services1" className="Drawer-a">
              <div className="Drawer-row">
                <span className="Drawericon"><CompassCalibrationIcon /></span>
                <a className="Drawertitle">Services</a>
              </div>
            </NavLink>
            </li>
            <li className="active"><NavLink to="/Adds" className="Drawer-a">
              <div className="Drawer-row">
                <span className="Drawericon"><AddBusinessIcon /></span>
                <a className="Drawertitle">Adds</a>
              </div>
            </NavLink>
            </li>
            <li className="active"><NavLink to="/Settings" className="Drawer-a">
              <div className="Drawer-row">
                <span className="Drawericon"><SettingsIcon /></span>
                <a className="Drawertitle">Settings</a>
              </div>
            </NavLink>
            </li> */}
            {Drawerdata.map((val, key) => {
              return (
                <li key={key} className="active">
                  <Link to={val.link} key={key} className="Drawer-a">
                    <div className="Drawer-row">
                      <div className="Drawericon">{val.icon}</div>
                      <div className="Drawertitle">{val.title}</div>
                    </div>
                  </Link>
                </li>
              );
            })}
          </ul>
        </div>
      </Drawer>
      {renderMobileMenu}
      {renderMenu}
    </Box>
  );
}
