import React, { useState, useEffect } from "react";
import "../Css/common.css";
import Web from "../Components/Web";
import Web1 from "../Components/Web1";
import Mark from "../Components/Mark";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";
import Slider from "react-slick";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function Creative(props) {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const settings = {
    dots: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 5000,
    pauseOnFocus: false,
    pauseOnHover: false,
    pauseOnDotsHover: false,
    slidesToShow: 2,
    slidesToScroll: 1,
    arrows: false,
    width: 540,

    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
    ],
  };

  // const [data13, setdata13] = useState([]);

  // const [data14, setdata14] = useState([]);

  // const [data18, setdata18] = useState([]);

  // const [data19, setdata19] = useState([]);

  // useEffect(() => {
  //   datas();
  // }, []);

   const api_url = "https://api.digitalraiz.co.in";

  // const datas = () => {
  //   axios
  //     .post("https://api.digitalraiz.co.in/api/web/landing/getitems", {}, {})
  //     .then(
  //       (res) => {
  //         if (res.status === 200) {
  //           setdata13(res.data.portfolioHeadingResult[0]);

  //           setdata14(res.data.portfolioMobileResult);

  //           setdata18(res.data.portfoliowebResult);

  //           setdata19(res.data.portfolioMarketResult);

  //         }
  //       },
  //       (error) => {
  //         if (error.response && error.response.status === 400) {
  //           toast(error.response.data.message);
  //         }
  //       }
  //     );
  // };


  console.log([props])

  return (
    <div>
      <div className="section-header">
        <h2>{props.data13.title}</h2>
        <div className="row">
          <p className="col-10 offset-1 text-center">{props.data13.description}</p>
        </div>
      </div>

      <div>
        <Box sx={{ width: "100%" }}>
          <Box sx={{ borderColor: "divider" }} className="tab213 ">
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              {/* {data3.map((data, i) => {
              return (
                <> <Tab
                label={data.title}
                {...a11yProps(i)}
                className="tabs123"
              /></>
                );
              })} */}

              <Tab
                label="Mobile Application"
                {...a11yProps(0)}
                className="tabs123"
              />

              <Tab
                label="Web Application"
                {...a11yProps(1)}
                className="tabs123"
              />
              <Tab
                label="Digital Marketing"
                {...a11yProps(2)}
                className="tabs123"
              />
            </Tabs>
          </Box>
          <TabPanel value={value} index={0}>
            <div className="container">
              <Slider {...settings}>
                {props.data14.map((data, i) => {
                  return (
                    <>
                      <div className="cardpad">
                        <div className="banner-blk">
                          <div className="row ">
                            <div className="col-lg-4">
                              <div>
                                <img
                                  src={api_url + "/" + data.image}
                                  className="portfoliologo"
                                />
                              </div>
                            </div>
                            <div className="col-lg-8">
                              <div className="featured-content">
                                <h5 className="mt-3">{data.title}</h5>
                                <div className="footer-social-links">
                                  {data.technologyDetails.map((datas, i) => {
                                    return (
                                      <>
                                        {" "}
                                        <a href="#" className="cardbtn">
                                          {datas.title}{" "}
                                        </a>
                                      </>
                                    );
                                  })}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div>
                            <p>{data.description.slice(0, 150)}</p>

                            <div className="btn-read-more">
                              <a
                                className="btn theme-btn"
                                target="_blank"
                                href={data.projectLink}
                              >
                                Link is available{" "}
                                <i
                                  class="fa fa-arrow-right"
                                  aria-hidden="true"
                                ></i>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  );
                })}
              </Slider>
            </div>
          </TabPanel>
          <TabPanel value={value} index={1}>
            <div className="container">
              <Slider {...settings}>
                {props.data20.map((data, i) => {
                  return (
                    <>
                      <div className="cardpad">
                        <div className="banner-blk">
                          <div className="row ">
                            <div className="col-lg-4">
                              <div>
                                <img
                                  src={api_url + "/" + data.image}
                                  className="portfoliologo"
                                />
                              </div>
                            </div>
                            <div className="col-lg-8">
                              <div className="featured-content">
                                <h5 className="mt-3">{data.title}</h5>
                                <div className="footer-social-links">
                                  {data.technologyDetails.map((datas, i) => {
                                    return (
                                      <>
                                        {" "}
                                        <a href="#" className=" cardbtn">
                                          {datas.title}{" "}
                                        </a>
                                      </>
                                    );
                                  })}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div>
                            <p>{data.description.slice(0, 150)}</p>

                            <div className="btn-read-more">
                              <a
                                className="btn theme-btn"
                                target="_blank"
                                href={data.projectLink}
                              >
                                Link is available{" "}
                                <i
                                  class="fa fa-arrow-right"
                                  aria-hidden="true"
                                ></i>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  );
                })}
              </Slider>
            </div>
          </TabPanel>
          <TabPanel value={value} index={2}>
            <div className="container">
              <Slider {...settings}>
                {props.data19.map((data, i) => {
                  return (
                    <>
                      <div className="cardpad">
                        <div className="banner-blk">
                          <div className="d-flex justify-content-center">
                            <img
                              src={api_url + "/" + data.image}
                              className="portfoliologo"
                              style={{ height: "120px" }}
                            />
                          </div>

                          <div>
                            <p>{data.description.slice(0, 150)}</p>

                            <div className="btn-read-more">
                              <a
                                className="btn theme-btn"
                                href={data.projectLink}
                                target="_blank"
                              >
                                Link is available{" "}
                                <i
                                  class="fa fa-arrow-right"
                                  aria-hidden="true"
                                ></i>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  );
                })}
              </Slider>
            </div>
          </TabPanel>

          {/* <TabPanel value={value} index={0}>
          })}
            <Web />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <Web1 />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <Mark />
          </TabPanel> */}
        </Box>
      </div>
    </div>
  );
}

export default Creative;
