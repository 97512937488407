import React, { useState, useEffect } from "react";
import Drawer from "./Drawers";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import { Row, Col, Card, CardBody } from "reactstrap";
import { Table } from "reactstrap";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import Modal from "react-bootstrap/Modal";
import EditIcon from "@mui/icons-material/Edit";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useTheme } from "@mui/material/styles";
import Chip from "@mui/material/Chip";
import ReactPaginate from "react-paginate";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function Portfolio() {
  function getStyles(name, personName, theme) {
    return {
      fontWeight:
        personName.indexOf(name) === -1
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium,
    };
  }
  const theme = useTheme();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [form, setform] = useState({});
  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);
  const [user1, setuser1] = useState([]);
  const [user, setuser] = useState([]);
  const [Files, setFiles] = useState("");
  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);

  const [form2, setform2] = useState([]);

  const [personName, setPersonName] = useState([]);

  const handleChangess = (event) => {
    const {
      target: { value },
    } = event;
    setPersonName(typeof value === "string" ? value.split(",") : value);
  };

  const [personName1, setPersonName1] = useState([]);

  const handleChangesss = (event) => {
    const {
      target: { value },
    } = event;
    setPersonName1(typeof value === "string" ? value.split(",") : value);
  };

  const [Files1, setFiles1] = useState("");

  const handleChange = (e) => {
    let myUser = { ...form };
    myUser[e.target.name] = e.target.value;
    setform(myUser);
  };

  const changeHandler = (e) => {
    setFiles(e.target.files);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    addCategory();
  };

  const [tech, settech] = useState([]);

  useEffect(() => {
    getCategory();
    getCategory1();
    getCategory2();
    getservices();
  }, []);

  const getCategory1 = () => {
    var token = sessionStorage.getItem("token");
    axios
      .get(
        "https://api.digitalraiz.co.in/api/admin/tech/getalltech",

        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((res) => {
        settech(res.data.technologyResult);
      });
  };

  const getCategory = () => {
    var token = sessionStorage.getItem("token");
    axios
      .get(
        "https://api.digitalraiz.co.in/api/admin/portfolio/getallportfolio",

        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((res) => {
        setuser(res.data.portResult);
        console.log(res.data.portResult);
      });
  };

  const api_url = "https://api.digitalraiz.co.in";

  const addCategory = () => {
    const dataArray = new FormData();
    dataArray.append("title", form.title);
    dataArray.append("description", form.description);
    // dataArray.append("isMobApp", form.isMobApp);
    // dataArray.append("isWebApp", form.isWebApp);
    // dataArray.append("isMarketing", form.isMarketing);
    dataArray.append("projectLink", form.projectLink);
    dataArray.append("serviceId", form2._id);
    dataArray.append("status", form.status);
    for (let i = 0; i < personName.length; i++) {
      dataArray.append("technologyId", personName[i]);
    }
    for (let i = 0; i < Files.length; i++) {
      dataArray.append("portImg", Files[i]);
    }
    var token = sessionStorage.getItem("token");
    axios
      .post(
        "https://api.digitalraiz.co.in/api/admin/portfolio/addportfolio",
        dataArray,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(
        (res) => {
          if (res.status === 200) {
            toast(res.data.message);
            handleClose();
            getCategory();
            clearForm();
          }
        },
        (error) => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message);
          }
        }
      );
  };

  const handleChange1 = (e) => {
    let myUser = { ...user1 };
    myUser[e.target.name] = e.target.value;
    setuser1(myUser);
  };

  const handleSubmit1 = (e) => {
    e.preventDefault();
    updateCategory();
  };

  const getpopup = (data) => {
    setuser1(data);
    setPersonName1(data.technologyId);
    handleShow1();
    handleSubmit1(data);
  };
  const changeHandler1 = (e) => {
    setFiles1(e.target.files);
  };

  const updateCategory = () => {
    const data1 = user1._id;
    const dataArray = new FormData();
    dataArray.append("title", user1.title);
    dataArray.append("description", user1.description);
    dataArray.append("serviceId", user1.serviceId);
    // dataArray.append("isMobApp", user1.isMobApp);
    // dataArray.append("isWebApp", user1.isWebApp);
    // dataArray.append("isMarketing", user1.isMarketing);
    dataArray.append("projectLink", user1.projectLink);
    dataArray.append("status", user1.status);
    for (let i = 0; i < personName1.length; i++) {
      dataArray.append("technologyId", personName1[i]);
    }
    for (let i = 0; i < Files1.length; i++) {
      dataArray.append("portImg", Files1[i]);
    }
    var token = sessionStorage.getItem("token");
    axios
      .put(
        "https://api.digitalraiz.co.in/api/admin/portfolio/editportfolio" +
          "/" +
          data1,
        dataArray,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(
        (res) => {
          if (res.status === 200) {
            toast(res.data.message);
            handleClose1();
            getCategory();
          }
        },
        (error) => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message);
          }
        }
      );
  };

  const manageDelete = (data) => {
    const confirmBox = window.confirm("Do you really want to Delete?");
    if (confirmBox === true) {
      deleteUser(data);
    }
  };

  const deleteUser = (data) => {
    var token = sessionStorage.getItem("token");
    const data1 = data._id;
    console.log(data1);
    axios
      .delete(
        "https://api.digitalraiz.co.in/api/admin/portfolio/removeportfolio" +
          "/" +
          data1,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(
        (res) => {
          if (res.status === 200) {
            toast(res.data.message);
            getCategory();
          }
        },
        (error) => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message);
          }
        }
      );
  };

  const [listPerPage] = useState(10);
  const [pageNumber, setPageNumber] = useState(0);

  const pagesVisited = pageNumber * listPerPage;
  const lists = user.slice(pagesVisited, pagesVisited + listPerPage);
  const pageCount = Math.ceil(user.length / listPerPage);

  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };

  const clearForm = () => {
    setform({
      title: "",
      description: "",
    });
  };

  const [users, setusers] = useState([]);

  const handleChanges = (e) => {
    let myUser = { ...users };
    myUser[e.target.name] = e.target.value;
    setusers(myUser);
  };

  const getCategory2 = () => {
    var token = sessionStorage.getItem("token");
    axios
      .post(
        "https://api.digitalraiz.co.in/api/admin/portfoHead/getportfcntnt",
        {},

        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((res) => {
        setusers(res.data.portfResult);
      });
  };
  const handleSubmit2 = (e) => {
    e.preventDefault();
    updateCategory1();
  };

  const updateCategory1 = () => {
    const data1 = users._id;
    var token = sessionStorage.getItem("token");

    const data = { description: users.description, title: users.title };

    axios
      .put(
        "https://api.digitalraiz.co.in/api/admin/portfoHead/editportfcntnt" +
          "/" +
          data1,
        data,

        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(
        (res) => {
          if (res.status === 200) {
            toast(res.data.message);
            handleClose2();
            getCategory1();
          }
        },
        (error) => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message);
          }
        }
      );
  };

  const [services, setservices] = useState([]);

  const Optionchange3 = (e) => {
    let myUser = { ...form2 };
    myUser._id = e.target.value;
    setform2(myUser);
  };

  const Optionchange4 = (e) => {
    let myUser = { ...user1 };
    myUser.serviceId = e.target.value;
    setuser1(myUser);
  };

  const getservices = () => {
    var token = sessionStorage.getItem("token");
    axios
      .post(
        "https://api.digitalraiz.co.in/api/admin/service/getallservicestatus",
        {},

        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((res) => {
        setservices(res.data.serviceResult);
        console.log(res.data.serviceResult);
      });
  };

  return (
    <div>
      <Box
        sx={{ display: "flex" }}
        style={{ background: "#ebeaea", padding: "111px 0 0 0" }}
        className="cardmrg"
      >
        <Drawer></Drawer>
        <CssBaseline />
        <Box
          component="main"
          style={{ background: "#ebeaea" }}
          sx={{ flexGrow: 2, p: 4 }}
        >
          {" "}
          <Card className="cardstyle">
            {" "}
            <CardBody>
              {" "}
              <div className="row">
                <div className="col">
                  <h5>Portfolio Content</h5>
                </div>
                <div className="col">
                  <span style={{ float: "right" }}>
                    {" "}
                    <button
                      className="btn btn filter mb-1"
                      onClick={handleShow2}
                    >
                      {" "}
                      + Edit
                    </button>
                  </span>
                </div>
              </div>
              <Row className="mt-5">
                <Col md={2} style={{ textAlign: "center" }}>
                  Title:
                </Col>

                <Col md={10}>
                  <p>{users.title}</p>
                </Col>
              </Row>
              <Row className="mt-2">
                <Col md={2} style={{ textAlign: "center" }}>
                  Description:
                </Col>

                <Col md={10}>
                  <p>{users.description}</p>
                </Col>
              </Row>
            </CardBody>
          </Card>
          <Card className="cardstyle">
            {" "}
            <CardBody>
              {" "}
              <div className="row">
                <div className="col">
                  <h5>Portfolio</h5>
                </div>
                <div className="col">
                  <span style={{ float: "right" }}>
                    {" "}
                    <button
                      className="btn btn filter mb-3"
                      onClick={handleShow}
                    >
                      {" "}
                      + Add New One
                    </button>
                  </span>
                </div>
              </div>
              <div className="table-responsive mt-3">
                <table
                  class="table table-striped table-hover dataTable"
                  id="xin_table"
                  border="1"
                >
                  <thead
                  // className="thead-light"
                  // style={{ background: "#1562a0", color: "white" }}
                  >
                    <tr>
                      <th>Sl.no</th>
                      <th>Title</th>
                      <th>Image</th>
                      <th>Description</th>
                      <th>Technologys</th>
                      <th>Services</th>
                      {/* <th>Link</th> */}
                      <th>status</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {" "}
                    {lists.map((data, i) => {
                      return (
                        <>
                          <tr key={i}>
                            <td>{(pageNumber - 1) * 10 + i + 11}</td>
                            <td>{data.title}</td>
                            <td>
                              <img
                                src={api_url + "/" + data.image}
                                style={{
                                  width: "100px",
                                  cursor: "pointer",
                                }}
                              ></img>
                            </td>
                            <td>{data.description}</td>

                            <td>
                              {data.technologyDetails.map((datas, i) => {
                                return <>{datas.title}</>;
                              })}
                            </td>
                            <td>{data.serviceName}</td>
                            {/* <td>{data.projectLink}</td> */}

                            <td>
                              {data.status === true || data.status == "true"
                                ? "Active"
                                : "InActive"}
                            </td>
                            <td>
                              <EditIcon
                                onClick={() => {
                                  getpopup(data);
                                }}
                                style={{ fontSize: "30px", color: "black" }}
                              />
                              <DeleteForeverIcon
                                onClick={() => {
                                  manageDelete(data);
                                }}
                                style={{ fontSize: "30px", color: "red" }}
                              />
                            </td>
                          </tr>
                        </>
                      );
                    })}
                  </tbody>
                </table>
              </div>
              <div className="mt-3" style={{ float: "right" }}>
                <a style={{ cursor: "pointer" }}>
                  <ReactPaginate
                    previousLabel={"Previous"}
                    nextLabel={"Next"}
                    pageCount={pageCount}
                    onPageChange={changePage}
                    containerClassName={"pagination"}
                    previousLinkClassName={"previousBttn"}
                    nextLinkClassName={"nextBttn"}
                    disabledClassName={"disabled"}
                    activeClassName={"active"}
                    total={lists.length}
                  />
                </a>
              </div>
              <ToastContainer></ToastContainer>
            </CardBody>
          </Card>
        </Box>
      </Box>
      <div>
        <Modal show={show} onHide={handleClose} style={{ marginTop: "80px" }}>
          <Modal.Header>
            <Modal.Title>
              <h3>Add</h3>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form
              method="post"
              onSubmit={(e) => {
                handleSubmit(e);
              }}
            >
              <div class="container">
                <label>Title: </label>
                <input
                  type="text"
                  className="form-control uuu"
                  placeholder="Enter title"
                  // required
                  name="title"
                  value={form.title}
                  onChange={(e) => {
                    handleChange(e);
                  }}
                />

                <FormControl sx={{ width: "100%" }}>
                  <label className="mt-2">Technology: </label>
                  <Select
                    labelId="demo-multiple-chip-label"
                    id="demo-multiple-chip"
                    multiple
                    name="technologyId"
                    value={personName}
                    onChange={handleChangess}
                    input={
                      <OutlinedInput
                        id="select-multiple-chip"
                        label="Chip"
                        style={{
                          background: "white",
                        }}
                      />
                    }
                    renderValue={(selected) => {
                      return (
                        <>
                          {selected.map((value, o) => {
                            const option = tech.find((o) => o._id === value);
                            return <Chip key={value} label={option.title} />;
                          })}
                        </>
                      );
                    }}
                    MenuProps={MenuProps}
                  >
                    {tech.map((name, i) => (
                      <MenuItem
                        key={i}
                        value={name._id}
                        style={getStyles(name.title, personName, theme)}
                      >
                        {name.title}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                <label className="mt-3">Image :</label>
                <br></br>
                <span style={{ fontSize: "10px", color: "red" }}>
                  Size :	138 × 138 px
                </span>
                <input
                  type="file"
                  className="form-control uuu"
                  name="image"
                  multiple
                  onChange={changeHandler}
                  // required
                />

                <label className="mt-4">Services :</label>
                <select
                  class="form-control form-select uuu"
                  // required
                  name="serviceId"
                  value={form2.serviceId}
                  onChange={(e) => {
                    Optionchange3(e);
                  }}
                >
                  <option value="">select</option>
                  {services.map((opt) => {
                    return <option value={opt._id}>{opt.title}</option>;
                  })}
                </select>

                <label className="mt-3">Description :</label>
                <textarea
                  class="form-control uuu"
                  id="exampleFormControlTextarea3 "
                  rows="3"
                  name="description"
                  // required
                  value={form.description}
                  onChange={(e) => {
                    handleChange(e);
                  }}
                ></textarea>

                <label>Link: </label>
                <input
                  type="text"
                  className="form-control uuu"
                  placeholder="Enter"
                  // required
                  name="projectLink"
                  value={form.projectLink}
                  onChange={(e) => {
                    handleChange(e);
                  }}
                />

                <label className="mt-3">Status :</label>
                <select
                  className="form-control form-select uuu"
                  // required
                  name="status"
                  onChange={(e) => {
                    handleChange(e);
                  }}
                >
                  <option value="">select</option>
                  <option value="true">Active</option>
                  <option value="false">Inactive</option>
                </select>

                <div class="text-center mt-3 float-right">
                  <button
                    style={{ margin: "0px 0px 0px 40px", color: "white" }}
                    type="button"
                    class="btn btn-sm btn-danger mr-3"
                    onClick={handleClose}
                  >
                    <i class="fa fa-times-circle"></i>
                    <span aria-hidden="true"> Cancel</span>
                  </button>
                  <button
                    type="submit"
                    class="btn btn-sm btn-success save"
                    style={{ color: "white" }}
                  >
                    <i class="fa fa-check-circle"></i> Submit
                  </button>
                </div>
              </div>
            </form>
          </Modal.Body>
        </Modal>

        <Modal show={show1} onHide={handleClose1} style={{ marginTop: "80px" }}>
          <Modal.Header>
            <Modal.Title>
              <h3>Edit </h3>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form
              method="post"
              onSubmit={(e) => {
                handleSubmit1(e);
              }}
            >
              {" "}
              <div class="container">
                <label>Title: </label>
                <input
                  type="text"
                  className="form-control uuu"
                  placeholder="Enter title"
                  // required
                  name="title"
                  value={user1.title}
                  onChange={(e) => {
                    handleChange1(e);
                  }}
                />

                {/* <FormControl sx={{ width: "100%" }}>
                  <label className="mt-2">Technology: </label>
                  <Select
                    labelId="demo-multiple-chip-label"
                    id="demo-multiple-chip"
                    multiple
                    name="technologyId"
                    value={personName1}
                    onChange={handleChangesss}
                    input={
                      <OutlinedInput
                        id="select-multiple-chip"
                        label="Chip"
                        style={{
                          background: "white",
                        }}
                      />
                    }
                    renderValue={(selected) => {
                      return (
                        <>
                          {selected.map((value, o) => {
                            const option = tech.find((o) => o._id === value);
                            return <Chip key={value} label={option.title} />;
                          })}
                        </>
                      );
                    }}
                    MenuProps={MenuProps}
                  >
                    {tech.map((name, i) => (
                      <MenuItem
                        key={i}
                        value={name._id}
                        style={getStyles(name.title, personName, theme)}
                      >
                        {name.title}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl> */}
                <FormControl sx={{ width: "100%" }}>
                  <label className="mt-2">Technology: </label>
                  <Select
                    labelId="demo-multiple-chip-label"
                    id="demo-multiple-chip"
                    multiple
                    name="technologyId"
                    value={personName1}
                    onChange={handleChangesss}
                    input={
                      <OutlinedInput id="select-multiple-chip" label="Chip" />
                    }
                    renderValue={(personName1) => personName1.join("title")}
                    MenuProps={MenuProps}
                  >
                    {tech.map((name, i) => (
                      <MenuItem
                        key={i}
                        value={name._id}
                        style={getStyles(name.title, personName1, theme)}
                      >
                        {name.title}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <label className="mt-3">Image :</label>
                <input
                  type="file"
                  className="form-control uuu"
                  name="image"
                  multiple
                  onChange={changeHandler1}
                />

                <label className="mt-4">Services :</label>
                <select
                  class="form-control form-select uuu"
                  // required
                  name="serviceId"
                  value={user1.serviceId}
                  onChange={(e) => {
                    Optionchange4(e);
                  }}
                >
                  <option value="">select</option>
                  {services.map((opt) => {
                    return <option value={opt._id}>{opt.title}</option>;
                  })}
                </select>

                <label className="mt-3">Description :</label>
                <textarea
                  class="form-control uuu"
                  id="exampleFormControlTextarea3 "
                  rows="3"
                  name="description"
                  // required
                  value={user1.description}
                  onChange={(e) => {
                    handleChange1(e);
                  }}
                ></textarea>

                <label>Link: </label>
                <input
                  type="text"
                  className="form-control uuu"
                  placeholder="Enter"
                  // required
                  name="projectLink"
                  value={user1.projectLink}
                  onChange={(e) => {
                    handleChange1(e);
                  }}
                />

                <label className="mt-3">Status :</label>
                <select
                  className="form-control form-select uuu"
                  // required
                  value={user1.status}
                  name="status"
                  onChange={(e) => {
                    handleChange1(e);
                  }}
                >
                  <option value="">select</option>
                  <option value="true">Active</option>
                  <option value="false">Inactive</option>
                </select>

                <div class="text-center mt-3 float-right">
                  <button
                    style={{ margin: "0px 0px 0px 40px", color: "white" }}
                    type="button"
                    class="btn btn-sm btn-danger mr-3"
                    onClick={handleClose1}
                  >
                    <i class="fa fa-times-circle"></i>
                    <span aria-hidden="true"> Cancel</span>
                  </button>
                  <button
                    type="submit"
                    class="btn btn-sm btn-success save"
                    style={{ color: "white" }}
                  >
                    <i class="fa fa-check-circle"></i> Submit
                  </button>
                </div>
              </div>
            </form>
          </Modal.Body>
        </Modal>
        <Modal show={show2} onHide={handleClose2} style={{ marginTop: "80px" }}>
          <Modal.Header>
            <Modal.Title>
              <h3>Edit</h3>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form
              method="post"
              onSubmit={(e) => {
                handleSubmit2(e);
              }}
            >
              <div class="container">
                <label>Title: </label>
                <input
                  type="text"
                  className="form-control uuu"
                  placeholder="Enter title"
                  // required
                  name="title"
                  value={users.title}
                  onChange={(e) => {
                    handleChanges(e);
                  }}
                />

                <label className="mt-3">Description :</label>
                <textarea
                  class="form-control uuu"
                  id="exampleFormControlTextarea3 "
                  rows="3"
                  name="description"
                  // required
                  value={users.description}
                  onChange={(e) => {
                    handleChanges(e);
                  }}
                ></textarea>

                <div class="text-center mt-3 float-right">
                  <button
                    style={{ margin: "0px 0px 0px 40px", color: "white" }}
                    type="button"
                    class="btn btn-sm btn-danger mr-3"
                    onClick={handleClose2}
                  >
                    <i class="fa fa-times-circle"></i>
                    <span aria-hidden="true"> Cancel</span>
                  </button>
                  <button
                    type="submit"
                    class="btn btn-sm btn-success save"
                    style={{ color: "white" }}
                  >
                    <i class="fa fa-check-circle"></i> Submit
                  </button>
                </div>
              </div>
            </form>
          </Modal.Body>
        </Modal>
        <ToastContainer />
      </div>
    </div>
  );
}

export default Portfolio;
