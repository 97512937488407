import React from 'react';
import { FaTrophy, FaCheckCircle, FaUserCheck, FaLightbulb } from 'react-icons/fa';

const cardStyle = {
  boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
  border: 'none',
  transition: 'transform 0.2s, box-shadow 0.2s, background-color 0.2s',
  cursor: 'pointer',
  padding: '13px',
  fontSize: '13px',
  height: '180px',                         
  width: '100%', 
};

const AddingNew = () => {
  return (
    <section className='container'>
      <h4 className='text-center' style={{
        fontSize: '34px',
        fontWeight: '500',
        lineHeight: '48px',
        color: '#263b5e',
        marginBottom: '15px',
        textTransform: 'capitalize',
        background: 'linear-gradient(to right, #901ec1 2%, #7450fe 82%)',
        WebkitBackgroundClip: 'text',
        WebkitTextFillColor: 'transparent',
      }}>
        Why Choose Us?
      </h4>
      <div className='row'>
        <div className='col-sm-12 col-md-6'>
          <img 
            src='https://www.sapphiresolutions.net/images/why_choose_us_icons/why_banner.png' 
            alt='Why Choose Us?' 
            className='img-fluid mt-5 floating-image' 
          />
        </div>
        <div className='col-sm-12 col-md-6'>
          <p style={{ fontSize: '15px' }}>
            At DigitalRaiz, we leverage the latest technologies and trends to deliver innovative solutions tailored to your needs.
          </p>
          <div className='row'>
            {[
              { title: 'Proven Track Record', text: 'Over 300 successful projects in 8 years.', icon: <FaTrophy size={40} color="#7450fe" /> },
              { title: 'High Success Rate', text: '99% success rate highlights our commitment.', icon: <FaCheckCircle size={40} color="#7450fe" /> },
              { title: 'Client Trust', text: 'Our clients trust us for quality and reliability.', icon: <FaUserCheck size={40} color="#7450fe" /> },
              { title: 'Innovative Solutions', text: 'Using the latest tech for cutting-edge results.', icon: <FaLightbulb size={40} color="#7450fe" /> }
            ].map((card, index) => (
              <div className='col-sm-12 col-md-6 mb-4' key={index}>
                <div 
                  className='card shadow-sm' 
                  style={cardStyle}
                >
                  <div className='card-body text-center'>
                    <div className='mb-3'>
                      {card.icon}
                    </div>
                    <b className='card-title mt-3'>{card.title}</b>
                    <p>{card.text}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* this is the CSSSS */}
      <style>
        {`
          .card:hover {
            transform: scale(1.05);
            box-shadow: 0 6px 12px rgba(0,0,0,0.15);
          }

          @-webkit-keyframes floating2 {
            0% { transform: translateY(0); }
            50% { transform: translateY(-10px); }
            100% { transform: translateY(0); }
          }

          .floating-image {
            -webkit-animation: floating2 7s infinite;
            animation: floating2 7s infinite;
          }
        `}
      </style>
    </section>
  );
}

export default AddingNew;
