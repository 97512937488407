import React from "react";
import Slider from "react-slick";
import vision from "../assets/images/newimgs/web/wings.png";
import Skandaa from "../assets/images/newimgs/web/srisk.png";
import veditech from "../assets/images/newimgs/web/vedith.png";
import Nextitcareer from "../assets/images/newimgs/web/nextit.png";
import Microcare from "../assets/images/newimgs/web/microcare.png";
import icreate1 from "../assets/images/newimgs/web/icreate.png";
import drncc from "../assets/images/newimgs/web/drncc.png";
import drncc1 from "../assets/images/newimgs/drnc1.png";
import el8 from "../assets/images/newimgs/el8.png";
import kishan from "../assets/images/newimgs/kisanagro.png";

function Mark() {
  const settings = {
    dots: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 5000,
    pauseOnFocus: false,
    pauseOnHover: false,
    pauseOnDotsHover: false,
    slidesToShow: 2,
    slidesToScroll: 1,
    arrows: false,
    width: 540,

    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
    ],
  };
  return (
    <div>
      <div className="container">
        <Slider {...settings}>
          <div className="cardpad">
            <div className="banner-blk">
              <div className="d-flex justify-content-center">
                <img
                  src={icreate1}
                  className="portfoliologo"
                  style={{ height: "120px" }}
                />
              </div>

              <div>
                <p>
                  We Provide The Best BBA Courses In Hyderabad Being The Best
                  Commerce College In Hyderabad, And With Good Placements, And
                  the Best Faculty In Our College.
                </p>

                <div className="btn-read-more">
                  <a
                    className="btn theme-btn"
                    href=" https://www.icreateedu.in/"
                    target="_blank"
                  >
                    Link is available{" "}
                    <i class="fa fa-arrow-right" aria-hidden="true"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="cardpad">
            <div className="banner-blk">
              <div className="d-flex justify-content-center">
                <img
                  src={Microcare}
                  className="portfoliologo"
                  style={{ height: "120px" }}
                />
              </div>
              <div>
                <p>
                  Are You Looking For The Best ENT Hospitals In Hyderabad
                  Microcare ENT Hospital Is One Of The Top ENT Hospitals In
                  Kukatpally Hyderabad.
                </p>

                <div className="btn-read-more">
                  <a
                    className="btn theme-btn"
                    href="https://www.microenthospital.com/index.php"
                    target="_blank"
                  >
                    Link is available{" "}
                    <i class="fa fa-arrow-right" aria-hidden="true"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className="cardpad">
            <div className="banner-blk">
              <div className="d-flex justify-content-center">
                <img
                  src={Skandaa}
                  className="portfoliologo"
                  style={{ height: "120px" }}
                />
              </div>

              <div>
                <p>
                  The Best Real Estate Company In Hyderabad we provide you to
                  find your dream home and we will provide the best 2,3,4 BHK
                  flats in Hyderabad.
                </p>

                <div className="btn-read-more">
                  <a
                    className="btn theme-btn"
                    href="https://sriskandaarealtors.com/"
                    target="_blank"
                  >
                    Link is available{" "}
                    <i class="fa fa-arrow-right" aria-hidden="true"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="cardpad">
            <div className="banner-blk">
              <div className="d-flex justify-content-center">
                <img
                  src={veditech}
                  className="portfoliologo"
                  style={{ height: "120px" }}
                />
              </div>
              <div>
                <p>
                  VedithTech is the Best Software Training Institute for
                  Professional Data Science, Machine Learning, & we have
                  world-class experience trainers.{" "}
                </p>

                <div className="btn-read-more">
                  <a
                    className="btn theme-btn"
                    href="https://digitalraiz.com/projects/vedhitech/dynamic/"
                    target="_blank"
                  >
                    Link is available{" "}
                    <i class="fa fa-arrow-right" aria-hidden="true"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="cardpad">
            <div className="banner-blk">
              <div className="d-flex justify-content-center">
                <img
                  src={Nextitcareer}
                  className="portfoliologo"
                  style={{ height: "120px" }}
                />
              </div>
              <div>
                <p>
                  Best Online Training Institute For TOSCA, SELENIUM, MANUAL
                  TESTING, RPA, MYSQL, BIG DATA, SALESFORCE, API TESTING With
                  100% Job Guarantee Placement Assistance.
                </p>

                <div className="btn-read-more">
                  <a
                    className="btn theme-btn"
                    href=" https://nextitcareer.com/"
                    target="_blank"
                  >
                    Link is available{" "}
                    <i class="fa fa-arrow-right" aria-hidden="true"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="cardpad">
            <div className="banner-blk">
              <div className="d-flex justify-content-center">
                <img
                  src={vision}
                  className="portfoliologo"
                  style={{ height: "120px" }}
                />
              </div>
              <div>
                <p>
                  Visionwings Software philosophy is to assure the best quality
                  software products, our client satisfaction, on time delivery
                  of projects
                </p>

                <div className="btn-read-more">
                  <a
                    className="btn theme-btn"
                    target="_blank"
                    href="https://www.visionwings.com/"
                  >
                    Link is available{" "}
                    <i class="fa fa-arrow-right" aria-hidden="true"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className="cardpad">
            <div className="banner-blk">
              <div className="d-flex justify-content-center">
                <img
                  src={drncc1}
                  className="portfoliologo"
                  style={{ height: "120px" }}
                />
              </div>
              <div>
                <p>
                  DRNGMI Provides Tourism And Hospitality Management With
                  Catering Technology And Is The Best Hotel Management And BBA
                  College In Hyderabad Telangana.
                </p>

                <div className="btn-read-more">
                  <a
                    className="btn theme-btn"
                    target="_blank"
                    href="https://drngmi.com/"
                  >
                    Link is available{" "}
                    <i class="fa fa-arrow-right" aria-hidden="true"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="cardpad">
            <div className="banner-blk">
              <div className="d-flex justify-content-center">
                <img
                  src={el8}
                  className="portfoliologo"
                  style={{ height: "120px", padding: "20px" }}
                />
              </div>
              <div>
                <p>
                  Are You Searching for Security Service Companies? We are
                  Providing Security Guard Services for Hotels, Hospitals,
                  Software Companies, Malls...etc
                </p>

                <div className="btn-read-more">
                  <a
                    className="btn theme-btn"
                    target="_blank"
                    href="https://www.facebook.com/El8-Services-104239015173682/"
                  >
                    Link is available{" "}
                    <i class="fa fa-arrow-right" aria-hidden="true"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="cardpad">
            <div className="banner-blk">
              <div className="d-flex justify-content-center">
                <img
                  src={drncc}
                  className="portfoliologo"
                  style={{ height: "120px" }}
                />
              </div>
              <div>
                <p>
                  DRNCC Evolved from the need for world class business school in
                  India. We recognize that the rapidly changing business
                  landscape would require young...
                </p>

                <div className="btn-read-more">
                  <a
                    className="btn theme-btn"
                    target="_blank"
                    href="https://drncc.in/"
                  >
                    Link is available{" "}
                    <i class="fa fa-arrow-right" aria-hidden="true"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="cardpad">
            <div className="banner-blk">
              <div className="d-flex justify-content-center">
                <img
                  src={kishan}
                  className="portfoliologo"
                  style={{ height: "120px" }}
                />
              </div>
              <div>
                <p>
                  Kisan Agro Products is a reputed firm wholesaling and trading
                  in best series of Natural Basmati Rice and Organic Basmati
                  Rice, we also deals in good quality ..
                </p>

                <div className="btn-read-more">
                  <a
                    className="btn theme-btn"
                    target="_blank"
                    href="https://kisanagro.in/"
                  >
                    Link is available{" "}
                    <i class="fa fa-arrow-right" aria-hidden="true"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </Slider>
      </div>
    </div>
  );
}

export default Mark;
