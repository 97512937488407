import React from "react";
import logo2 from "../assets/images/newimgs/logo.png";
import thankyou from "../assets/images/thankyou1.png";
import { NavLink } from "react-router-dom";
import bannerbg from "../assets/images/shapes/banner_bg.png";

function Thankyou() {
  return (
    <div>
      <section className=" ">
        <div style={{ height: "490px" }} className="home-top-shape">
          <img src={bannerbg} style={{ width: "100%" }} />
        </div>
        <div className="bubbles-animate">
          <div className="bubble b_one" />
          <div className="bubble b_two" />
          <div className="bubble b_three" />
          <div className="bubble b_four" />
          <div className="bubble b_five" />
          <div className="bubble b_six" />
        </div>
        <div className="d-lg-flex">
          <div className="container d-lg-flex align-items-lg-center custompage-content">
            <div className="logo">
              <NavLink to="/">
                <img src={logo2} alt="IMG" />
              </NavLink>
            </div>

            <div className="error-404-content align-items-center text-center">
              <img className="img-fluid" src={thankyou} alt="IMG" />
              <p>
                Received Successfully. Our team will get back to you soon !
              </p>
              <NavLink style={{zIndex:"9999999999"}} className="btn theme-btn mt-4" to="/">
                Visit website
              </NavLink>
            </div>
            <div className="footer">
              <div className="container">
                <div className="d-flex justify-content-between align-items-center">
                  {/* Copyright */}
                  <p className="copyright mb-0"></p>
                  {/* End Copyright */}
                  <ul className="list-inline mb-0 share-buttons">
                    <li className="list-inline-item">
                      <a
                        className="btn btn-sm"
                        target="_blank"
                        href="https://www.facebook.com/digitalraiz/"
                      >
                        <span className="fa fa-facebook" />
                      </a>
                    </li>
                    <li className="list-inline-item">
                      <a
                        className="btn btn-sm"
                        target="_blank"
                        href="https://www.instagram.com/digital_raiz/"
                      >
                        <span className="fa fa-instagram" />
                      </a>
                    </li>
                    <li className="list-inline-item">
                      <a
                        className="btn btn-sm"
                        target="_blank"
                        href="https://twitter.com/digital_raiz/"
                      >
                        <span className="fa fa-twitter" />
                      </a>
                    </li>
                    <li className="list-inline-item">
                      <a
                        className="btn btn-sm"
                        target="_blank"
                        href="https://play.google.com/store/apps/developer?id=Digital+Raiz+Creative+Solutions"
                      >
                        <span className="fa fa-linkedin" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Thankyou;
