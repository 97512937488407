import React from 'react'
import "../Css/common.css";
import facebook from "../assets/images/newimgs/facebook.png";
import insta from "../assets/images/newimgs/insta1.png";
import linkedin from "../assets/images/newimgs/linkedin.png";
import twitter from "../assets/images/newimgs/twitter.png";
import play from "../assets/images/newimgs/play.png";
function Sidebar() {
  return (
    <div>
      <div>
        <div class="menu-container">
          <div class="collapse-icon">
            <span class="social_media_text"></span>  
            {/* <span class="fa fa-chevron-right"></span> */}
          </div>
          <div class="menu-item">
            <a href="https://www.facebook.com/digitalraiz/" target="_blank" style={{ padding: "0px" }}>
              <img src={facebook} alt="" />
              {/* <div class="menu-item-text">Facebook</div> */}
            </a>
          </div>
          <div class="menu-item">
            <a href="https://twitter.com/digital_raiz" target="_blank" style={{ padding: "0px" }}>
              <img src={twitter} alt="" />
              {/* <div class="menu-item-text">Twitter</div> */}
            </a>
          </div>

          <div class="menu-item">
            <a href="https://www.linkedin.com/company/71333221/admin/" target="_blank" style={{ padding: "0px" }}>
              <img src={linkedin} alt="" />
              {/* <div class="menu-item-text">Linkedin</div> */}
            </a>
          </div>
          <div class="menu-item">
            <a href="https://www.instagram.com/digital_raiz/" target="_blank" style={{ padding: "0px" }}>
              <img src={insta} alt="" />
              {/* <div class="menu-item-text">Youtube</div> */}
            </a>
          </div>
          <div class="menu-item">
            <a href="https://play.google.com/store/apps/developer?id=Digital+Raiz+Creative+Solutions" target="_blank" style={{ padding: "0px" }}>
              <img src={play} alt="" style={{ height: "25px" }} />
              {/* <div class="menu-item-text">Youtube</div> */}
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Sidebar