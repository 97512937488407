import React, { useState, useEffect } from "react";
import Drawer from "./Drawers";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import { Row, Col, Card, CardBody } from "reactstrap";
import { Table } from "reactstrap";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import Modal from "react-bootstrap/Modal";
import EditIcon from "@mui/icons-material/Edit";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import "react-toastify/dist/ReactToastify.css";
import ReactPaginate from "react-paginate";

function Certification() {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [form, setform] = useState({});
  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);
  const [user1, setuser1] = useState([]);
  const [user, setuser] = useState([]);
  const [Files, setFiles] = useState("");
  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);

  const [Files1, setFiles1] = useState("");

  const handleChange = (e) => {
    let myUser = { ...form };
    myUser[e.target.name] = e.target.value;
    setform(myUser);
  };

  const changeHandler = (e) => {
    setFiles(e.target.files);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    addCategory();
  };

  useEffect(() => {
    getCategory();
    getCategory1();
  }, []);

  const getCategory = () => {
    var token = sessionStorage.getItem("token");
    axios
      .get(
        "https://api.digitalraiz.co.in/api/admin/certificate/getallcert",

        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((res) => {
        setuser(res.data.certificateResult);
        console.log(res.data.certificateResult);
      });
  };

  const api_url = "https://api.digitalraiz.co.in";

  const addCategory = () => {
    const dataArray = new FormData();
    dataArray.append("status", form.status);
    for (let i = 0; i < Files.length; i++) {
      dataArray.append("certImg", Files[i]);
    }

    var token = sessionStorage.getItem("token");
    axios
      .post(
        "https://api.digitalraiz.co.in/api/admin/certificate/addcert",
        dataArray,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(
        (res) => {
          if (res.status === 200) {
            toast(res.data.message);
            handleClose();
            getCategory();
            clearForm();
          }
        },
        (error) => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message);
          }
        }
      );
  };

  const handleChange1 = (e) => {
    let myUser = { ...user1 };
    myUser[e.target.name] = e.target.value;
    setuser1(myUser);
  };

  const handleSubmit1 = (e) => {
    e.preventDefault();
    updateCategory();
  };

  const getpopup = (data) => {
    setuser1(data);
    handleShow1();
    handleSubmit1(data);
  };
  const changeHandler1 = (e) => {
    setFiles1(e.target.files);
  };

  const updateCategory = () => {
    var token = sessionStorage.getItem("token");
    const dataArray = new FormData();
    const data1 = user1._id;
    dataArray.append("status", user1.status);
    for (let i = 0; i < Files1.length; i++) {
      dataArray.append("certImg", Files1[i]);
    }
    axios
      .put(
        "https://api.digitalraiz.co.in/api/admin/certificate/editcert" +
          "/" +
          data1,
        dataArray,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(
        (res) => {
          if (res.status === 200) {
            toast(res.data.message);
            handleClose1();
            getCategory();
          }
        },
        (error) => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message);
          }
        }
      );
  };

  const manageDelete = (data) => {
    const confirmBox = window.confirm("Do you really want to Delete?");
    if (confirmBox === true) {
      deleteUser(data);
    }
  };

  const deleteUser = (data) => {
    var token = sessionStorage.getItem("token");
    const data1 = data._id;
    console.log(data1);
    axios
      .delete(
        "https://api.digitalraiz.co.in/api/admin/certificate/removecert" +
          "/" +
          data1,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(
        (res) => {
          if (res.status === 200) {
            toast(res.data.message);
            getCategory();
          }
        },
        (error) => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message);
          }
        }
      );
  };
  const [search, setsearch] = useState("");
  const [listPerPage] = useState(10);
  const [pageNumber, setPageNumber] = useState(0);

  const pagesVisited = pageNumber * listPerPage;
  const lists = user.slice(pagesVisited, pagesVisited + listPerPage);
  const pageCount = Math.ceil(user.length / listPerPage);

  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };

  const clearForm = () => {
    setform({
      title: "",
      description: "",
    });
  };

  const [users, setusers] = useState([]);

  const handleChanges = (e) => {
    let myUser = { ...users };
    myUser[e.target.name] = e.target.value;
    setusers(myUser);
  };

  const getCategory1 = () => {
    var token = sessionStorage.getItem("token");
    axios
      .post(
        'https://api.digitalraiz.co.in/api/admin/value/getvalue',
        {},

        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((res) => {
        setusers(res.data.valResult);
      });
  };

  const handleSubmit2 = (e) => {
    e.preventDefault();
    updateCategory1();
  };

  const updateCategory1 = () => {
    const data1 = users._id;
    var token = sessionStorage.getItem("token");

    const data = { description: users.description, title: users.title };

    axios
      .put(
        'https://api.digitalraiz.co.in/api/admin/value/editvalue' +
          "/" +
          data1,
        data,

        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(
        (res) => {
          if (res.status === 200) {
            toast(res.data.message);
            handleClose2();
            getCategory1();
          }
        },
        (error) => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message);
          }
        }
      );
  };

  return (
    <div>
      <Box
        sx={{ display: "flex" }}
        style={{ background: "#ebeaea", padding: "111px 0 0 0" }}
        className="cardmrg"
      >
        <Drawer></Drawer>
        <CssBaseline />
        <Box
          component="main"
          style={{ background: "#ebeaea" }}
          sx={{ flexGrow: 2, p: 4 }}
        >
          {" "}
          <Card className="cardstyle">
            {" "}
            <CardBody>
              {" "}
              <div className="row">
                <div className="col">
                  <h5>Certification Content</h5>
                </div>
                <div className="col">
                  <span style={{ float: "right" }}>
                    {" "}
                    <button
                      className="btn btn filter mb-1"
                      onClick={handleShow2}
                    >
                      {" "}
                      + Edit
                    </button>
                  </span>
                </div>
              </div>
              <Row className="mt-5">
                <Col md={2} style={{ textAlign: "center" }}>
                  Title:
                </Col>

                <Col md={10}>
                  <p>{users.title}</p>
                </Col>
              </Row>
              <Row className="mt-2">
                <Col md={2} style={{ textAlign: "center" }}>
                  Description:
                </Col>

                <Col md={10}>
                  <p>{users.description}</p>
                </Col>
              </Row>
            </CardBody>
          </Card>
          <Card className="cardstyle">
            {" "}
            <CardBody>
              {" "}
              <div className="row">
                <div className="col">
                  <h5>Certification Images</h5>
                </div>
                <div className="col">
                  <span style={{ float: "right" }}>
                    {" "}
                    <button
                      className="btn btn filter mb-3"
                      onClick={handleShow}
                    >
                      {" "}
                      + Add New One
                    </button>
                  </span>
                </div>
              </div>
              <div className="table-responsive mt-3">
                <Table
                  id="table-to-xls"
                  bordered
                  className="table-centered datatable dt-responsive nowrap "
                  style={{
                    borderCollapse: "collapse",
                    borderSpacing: 0,
                    width: "100%",
                  }}
                >
                  <thead
                    className="thead-light"
                    style={{ background: "#1562a0", color: "white" }}
                  >
                    <tr>
                      <th>Sl.no</th>

                      <th>Images</th>

                      <th>Status</th>
                      <th style={{ width: "130px" }}>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {lists.map((data, i) => {
                      return (
                        <>
                          <tr key={i}>
                            <td>{(pageNumber - 1) * 10 + i + 11}</td>

                            <td>
                              <img
                                src={api_url + "/" + data.image}
                                style={{
                                  width: "100px",
                                  cursor: "pointer",
                                }}
                              ></img>
                            </td>

                            <td>
                              {data.status === true || data.status == "true"
                                ? "active"
                                : "Inactive"}
                            </td>
                            <td>
                              <EditIcon
                                onClick={() => {
                                  getpopup(data);
                                }}
                                style={{ fontSize: "30px", color: "black" }}
                              />
                              <DeleteForeverIcon
                                onClick={() => {
                                  manageDelete(data);
                                }}
                                style={{ fontSize: "30px", color: "red" }}
                              />
                            </td>
                          </tr>
                        </>
                      );
                    })}
                  </tbody>
                </Table>
              </div>
              <div className="mt-3" style={{ float: "right" }}>
              <a  style={{ cursor: "pointer" }}>
                <ReactPaginate
                  previousLabel={"Previous"}
                  nextLabel={"Next"}
                  pageCount={pageCount}
                  onPageChange={changePage}
                  containerClassName={"pagination"}
                  previousLinkClassName={"previousBttn"}
                  nextLinkClassName={"nextBttn"}
                  disabledClassName={"disabled"}
                  activeClassName={"active"}
                  total={lists.length}
                /></a>
              </div>
            </CardBody>
          </Card>
        </Box>
      </Box>
      <div>
        <Modal show={show} onHide={handleClose} style={{ marginTop: "80px" }}>
          <Modal.Header>
            <Modal.Title>
              <h3>Add</h3>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form
              method="post"
              onSubmit={(e) => {
                handleSubmit(e);
              }}
            >
              <div class="container">
                <label className="mt-3">Image :</label>
                <br></br>
                <span style={{ fontSize: "10px", color: "red" }}>
                  Size :192 × 144 px
                </span>
                <input
                  type="file"
                  className="form-control uuu"
                  name="image"
                  multiple
                  onChange={changeHandler}
                  required
                />

                <label className="mt-3">Status :</label>
                <select
                  className="form-control form-select uuu"
                  required
                  name="status"
                  value={form.status}
                  onChange={(e) => {
                    handleChange(e);
                  }}
                >
                  <option value="">select</option>
                  <option value="true">Active</option>
                  <option value="false">Inactive</option>
                </select>

                <div class="text-center mt-3 float-right">
                  <button
                    style={{ margin: "0px 0px 0px 40px", color: "white" }}
                    type="button"
                    class="btn btn-sm btn-danger mr-3"
                    onClick={handleClose}
                  >
                    <i class="fa fa-times-circle"></i>
                    <span aria-hidden="true"> Cancel</span>
                  </button>
                  <button
                    type="submit"
                    class="btn btn-sm btn-success save"
                    style={{ color: "white" }}
                  >
                    <i class="fa fa-check-circle"></i> Submit
                  </button>
                </div>
              </div>
            </form>
          </Modal.Body>
        </Modal>

        <Modal show={show1} onHide={handleClose1} style={{ marginTop: "80px" }}>
          <Modal.Header>
            <Modal.Title>
              <h3>Edit </h3>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form
              method="post"
              onSubmit={(e) => {
                handleSubmit1(e);
              }}
            >
              <div class="container">
                <label>Image :</label>
                <br></br>
                <span style={{ fontSize: "10px", color: "red" }}>
                  Size :192 × 144 px
                </span>
                <input
                  type="file"
                  className="form-control uuu"
                  name="image"
                  multiple
                  onChange={changeHandler1}
                />

                <label className="mt-3">Status :</label>
                <select
                  className="form-control form-select  uuu"
                  required
                  name="status"
                  value={user1.status}
                  onChange={(e) => {
                    handleChange1(e);
                  }}
                >
                  <option value="">select</option>
                  <option value="true">Active</option>
                  <option value="false">Inactive</option>
                </select>

                <div class="text-center mt-3 float-right">
                  <button
                    style={{ margin: "0px 0px 0px 40px", color: "white" }}
                    type="button"
                    class="btn btn-sm btn-danger mr-3"
                    onClick={handleClose1}
                  >
                    <i class="fa fa-times-circle"></i>
                    <span aria-hidden="true"> Cancel</span>
                  </button>
                  <button
                    type="submit"
                    class="btn btn-sm btn-success save"
                    style={{ color: "white" }}
                  >
                    <i class="fa fa-check-circle"></i> Submit
                  </button>
                </div>
              </div>
            </form>
          </Modal.Body>
        </Modal>
        <Modal show={show2} onHide={handleClose2} style={{ marginTop: "80px" }}>
          <Modal.Header>
            <Modal.Title>
              <h3>Edit</h3>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form
              method="post"
              onSubmit={(e) => {
                handleSubmit2(e);
              }}
            >
              <div class="container">
                <label>Title: </label>
                <input
                  type="text"
                  className="form-control uuu"
                  placeholder="Enter title"
                  required
                  name="title"
                  value={users.title}
                  onChange={(e) => {
                    handleChanges(e);
                  }}
                />

                <label className="mt-3">Description :</label>
                <textarea
                  class="form-control uuu"
                  id="exampleFormControlTextarea3 "
                  rows="3"
                  name="description"
                  required
                  value={users.description}
                  onChange={(e) => {
                    handleChanges(e);
                  }}
                ></textarea>

                <div class="text-center mt-3 float-right">
                  <button
                    style={{ margin: "0px 0px 0px 40px", color: "white" }}
                    type="button"
                    class="btn btn-sm btn-danger mr-3"
                    onClick={handleClose2}
                  >
                    <i class="fa fa-times-circle"></i>
                    <span aria-hidden="true"> Cancel</span>
                  </button>
                  <button
                    type="submit"
                    class="btn btn-sm btn-success save"
                    style={{ color: "white" }}
                  >
                    <i class="fa fa-check-circle"></i> Submit
                  </button>
                </div>
              </div>
            </form>
          </Modal.Body>
        </Modal>
        <ToastContainer />
      </div>
    </div>
  );
}

export default Certification;
