import React, { useState, useEffect } from 'react'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Slider from "react-slick";
import logo2 from "../assets/images/newimgs/logo.png";
import author1 from "../assets/images/blog/author-1.jpg";
import author2 from "../assets/images/blog/author-2.jpg";
import author3 from "../assets/images/blog/author-3.jpg";
import author4 from "../assets/images/blog/author-4.jpg";
// import author5 from "../assets/images/blog/author-5.jpg";
import axios from "axios";
// import { ToastContainer, toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
import toast, { Toaster } from 'react-hot-toast';
import "../assets/css/style.css";
import DigitalRaizpdf from "../assets/images/newimgs/1. Company Profile Final One.pdf";
import DigitalraizCreativeSolutions from "../assets/images/newimgs/digitalraiz/DigitalraizCreativeSolutions.pdf";
import { NavLink, useNavigate } from "react-router-dom";
function Header() {
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [form, setform] = useState([])

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [sticky, setSticky] = useState("");

  useEffect(() => {
    // handleShow();

    window.addEventListener("scroll", isSticky);
    return () => {
      window.removeEventListener("scroll", isSticky);
    };


  }, []);
  const isSticky = () => {
    const scrollTop = window.scrollY;
    const stickyClass = scrollTop >= 200 ? "header-area sticky" : "";
    setSticky(stickyClass);
    console.log(stickyClass);
  };

  const classes = `${sticky}`;


  const settings1 = {
    // autoplay: true,
    // autoplaySpeed: 8000,
    // speed: 500,
    // infinite: true,
    // dots: true,
    // width:400,
    // // fade: true,
    // arrows: false,
    // slidesToShow: 3,
    // slidesToScroll: 1,

    dots: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 3500,
    pauseOnFocus: false,
    pauseOnHover: false,
    pauseOnDotsHover: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    width: 500,
    // responsive: [
    //   {
    //     breakpoint: 767,
    //     settings: {
    //       arrows: false,

    //       slidesToShow: 3,
    //     },
    //   },
    //   {
    //     breakpoint: 480,
    //     settings: {
    //       arrows: false,
    //       slidesToShow: 1,
    //     },
    //   },
    // ],
    responsive: [{
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 1
      }
    }]
  };

  const handlechange = (e) => {
    let myUser = { ...form };
    myUser[e.target.name] = e.target.value;
    setform(myUser);
  }

  const addUsers = () => {
    var params = {
      "name": form.name,
      "phone": form.phone,
      "email": form.email,
      "service": form.service,
    }
    axios.post("https://api.digitalraiz.co.in/api/user/leads/addLead", params, {
    }).then((res) => {
      console.log("success")
      toast.success("Added Successfully. Your data received, Our team will get back to you soon!");
      // userdata();
      handleClose();
      navigate('/thankyou')
    })

  }

  const usersSubmit = (e) => {
    e.preventDefault();
    addUsers();
  };

  return (
    <div>
      <header className={classes} id="header-area">
        <nav className="navbar navbar-expand-md fixed-top" style={{ background: "#eee1fc" }}>
          <div className="container">
            <div className='row' style={{ width: "100%" }}>
              <div className='col-3'>

                <div className=""><NavLink to="/" className="navbar-brand" ><img src={logo2} className="img-fluid moblogoimg" alt="Img" /></NavLink></div>
              </div>

              <div className='col-9'>
                <div className='headtab'>
                  <div className='row mt-3 mobtab'>
                    <div className='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6 headerbtnsm'>

                      <div className="control-form">
                        <a href={DigitalRaizpdf} target="_blank">
                          <button className="btn themes-btn btn-block headbtn"><a className='btna'>Company Profile <i class="fa fa-cloud-download ml-1" aria-hidden="true"></i></a></button>
                        </a>         
                        </div>
                    </div>

                    <div className='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6'>
                      <div className="control-form ml-2">
                        <a href={DigitalraizCreativeSolutions} target="_blank">
                          <button className="btn theme-btn btn-block headbtn1"><span className='btna text-white' >Portfolio <i class="fa fa-arrow-right ml-1" aria-hidden="true"></i></span></button>
                        </a>
                      </div>
                      {/* </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </nav>

      </header>

      <Modal
        size="lg"
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}

      >

      
       <div onClick={handleClose}>
          <i class="fa fa-times modalstyle " aria-hidden="true" ></i>
        </div> 
        <div className="row login-page ">
          <div className="col-md-5 mobileslider cross">
            <div className=' login-side-des'>
              <div className="container-fluid">
                <div className="login-side-block">

                  <a href=""><img src={logo2} alt="Logo" /></a>
                  <div className="login-reviews">
                    <div className="review-details-content">
                      <div className="owl-carousel review_details" id="review_details-2">
                        <Slider {...settings1} >
                          <div className="item">
                            <p>"Our Dr.Narayana group of institutions were very happy with digital raiz solutions.. very satisfied with their work and response..."</p>
                            <h5>Sravani reddy</h5>
                            {/* <h6>Creative Director</h6> */}
                          </div>
                          {/* End review item */}
                          {/* Start review item */}
                          <div className="item">
                            <p>" Digital Raiz team developed a mobile app for me. They are the best in app development more over they save my money and time. I suggest Digital Raiz for mobile app development."</p>
                            <h5>Ganesh</h5>
                            {/* <h6>Creative Director</h6> */}
                          </div>
                          {/* End review item */}
                          {/* Start review item */}
                          <div className="item">
                            <p>"Digital Raiz made a website and mobile app for us. Product is excellent and their support is outstanding. Thanks to staff and management."</p>
                            <h5> Roopesh </h5>
                            {/* <h6>Creative Director</h6> */}
                          </div>
                          {/* End review item */}
                          {/* Start review item */}
                          <div className="item">
                            <p>" Best website design company in Hyderabad...I am on of the client in Digital Raiz creative solutions, its a good customer services. our project will done at limited. Thankyou digital Raiz creative solutions for your support."</p>
                            <h5>Abdullah</h5>
                            {/* <h6>Creative Director</h6> */}
                          </div>
                          {/* End review item */}
                          {/* Start review item */}
                          <div className="item">
                            <p>"Very efficient and reliable team. Definitely recommend Digital Raiz Creative Solutions for those who are looking for digital marketing services within budget and on time. Excellent client services and support provided. Thanks Team!"</p>
                            <h5>Koti</h5>
                            {/* <h6>Creative Director</h6> */}
                          </div>
                        </Slider>
                      </div>
                    </div>

                  </div>

                </div>
              </div>
            </div>
          </div>

          <div className="col-md-7">
            <div className="container">

              <a href="" className="res-logo"><img src={logo2} alt="Logo" /></a>
              <div className="login-form modheight">
                <div className="login-form-head ">
                  <h2 ><span className='h2txt'>Welcome to</span> <span>DigitalRaiz</span></h2>
                  <p>Fill out the form to get started..</p>
                </div>
                <form onSubmit={usersSubmit}>
                  <div className="form-group">
                    <label className="form-label"
                      htmlFor="signinEmail"
                    >Full Name</label>
                    <div className="input-group">
                      <div className="input-icon">
                        <span className="fa fa-user-o" />
                      </div>
                      <input type="text" pattern="^[\w'\-,.][^0-9_!¡?÷?¿/\\+=@#$%ˆ&*(){}|~<>;:[\]]{2,}$" className="form-control" name="name" onChange={(e) => { handlechange(e); }} id="signinEmail" placeholder="Full Name" aria-label="Email address" required />
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="form-label" htmlFor="signinPassword">
                      Mobile
                    </label>
                    <div className="input-group">
                      <div className="input-icon">
                        <span className="fa fa-mobile" />
                      </div>
                      <input type="text" minlength="0" maxlength="10" pattern="\d{10}" className="form-control" name="phone" id="signinPassword" onChange={(e) => { handlechange(e); }} placeholder="Mobile No..." aria-label="Password" required />
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="form-label" htmlFor="signinPassword2"> Email Id</label>
                    <div className="input-group">
                      <div className="input-icon">
                        <span className="fa fa-envelope-o" />
                      </div>
                      <input type="email" pattern="(^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$)" className="form-control" name="email" id="signinPassword2" onChange={(e) => { handlechange(e); }} placeholder="Email Id" aria-label="Password" required />
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="form-label" htmlFor="signinPassword2">Services</label>
                    <div className="input-group">
                      <div className="input-icon">
                        <span className="fa fa-globe" />
                      </div>
                      <select className="form-control" name="service" id="signinPassword2" placeholder="Email Id" onChange={(e) => { handlechange(e); }} aria-label="Password" required >
                        <option value="">Select</option>
                        <option className="form-control" value="Creating a Mobile App">Creating a Mobile App</option>
                        <option className="form-control" value="Web Development">Web Development</option>
                        <option className="form-control" value="Flutter App Development">Flutter App Development</option>
                        <option className="form-control" cvalue="Andriod APP Development">Andriod APP Development</option>
                        <option className="form-control" cvalue="Custom App Development">Custom App Development</option>
                        <option className="form-control" cvalue="Others">Others</option>
                      </select>
                    </div>
                  </div>
                  <div className="form-group">
                  </div>
                  <div className="form-group">
                    <button className="btn theme-btn btn-block" type='submit'>Submit</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        {/* </Modal.Body> */}
        {/* <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary">Understood</Button>
        </Modal.Footer> */}
      </Modal>
      {/* <ToastContainer/> */}
      <Toaster
        position="top-right"
        reverseOrder={false}
      />
    </div>
  )
}

export default Header