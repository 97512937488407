import "./App.css";
import Main from "../src/Components/Main";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "../src/assets/css/flaticon.css";
import "react-multi-carousel/lib/styles.css";
import Userdata from "./Components/Userdata";
import Dashboard from "./Admin/Dashboard";
import Adddetails from "./Admin/Adddetails";
import Login from "./Admin/Login";
import Clients from "./Admin/Clients";
import Services from "./Admin/Services";
import Portfolio from "./Admin/Portfolio";
import Technologies from "./Admin/Technologies";
import Testimonial from "./Admin/Testimonial";
import Profile from "./Admin/Profile";
import Headersection from "./Admin/Headersection";
import Footersection from "./Admin/Footersection";
import Certification from "./Admin/Certification";
import Perfection from "./Admin/Perfection";
import About from "./Admin/About";
import Administrations from "./Admin/Administrations";
import Thankyou from "./Components/Thankyou";


function App() {
  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route path="/Login" element={<Login />} />
          <Route path="/thankyou" element={<Thankyou />} />
          <Route path="/" element={<Main />} />
          <Route path="/digitalraiz-data" element={< Userdata />} />
          {sessionStorage.getItem("AdminId") != "" &&
            sessionStorage.getItem("AdminId") ? (
            <>
              <Route path="/admin" element={<Dashboard />} key="reload" />
              <Route path="/Leads" element={<Adddetails />} />
              <Route path="/Profile" element={<Profile />} />
              <Route path="/clients" element={<Clients />} />
              <Route path="/services" element={<Services />} />
              <Route path="/Portfolio" element={<Portfolio />} />
              <Route path="/Testimonial" element={<Testimonial />} />
              <Route path="/technologies" element={<Technologies />} />
              <Route path="/Headersection" element={<Headersection />} />
              <Route path="/Footersection" element={<Footersection />} />
              <Route path="/Certification" element={<Certification />} />
              <Route path="/Perfection" element={<Perfection />} />
              <Route path="/About" element={<About />} />
              <Route path="/Administrations" element={<Administrations />} />

            </>
          ) : (
            ""
          )}
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
