import React,{useState, useEffect} from "react";
import Drawer from "./Drawers";
import Box from "@mui/material/Box";
import { Link } from "react-router-dom";
import { styled } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import HistoryIcon from "@mui/icons-material/History";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import TempleHinduIcon from "@mui/icons-material/TempleHindu";
import GroupIcon from "@mui/icons-material/Group";
import Paper from "@mui/material/Paper";
import { Table, UncontrolledTooltip, Input, Label } from "reactstrap";
import { Row, Col, Card, CardBody, Media } from "reactstrap";
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import VisibilityIcon from '@mui/icons-material/Visibility';
import axios from "axios";
import { useNavigate } from 'react-router'
import { Redirect } from 'react-router-dom';
import ReactPaginate from "react-paginate";
import { URL } from "../Apiurl";

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));


const Dashboard = () => {
  // window.location.reload(false);
  let navigate = useNavigate();

  const [data1 ,setdata1]=useState([])

  useEffect(() => {
    // window.location.reload(true);
    // dataCount();
    // getCustomers();
    dashboard()
  }, []);


  const dashboard = () => {
    var token = sessionStorage.getItem("token");
    axios.post("https://api.digitalraiz.co.in/api/admin/dashboard/getdashdata",{}, {
      headers: { Authorization: `Bearer ${token}` },
    }).then((res) => {
      // if (res.status === 200) {
      console.log("success")
      // toast("Added Successfully ");
      setdata1(res.data)
      // handleClose1();
      // toggle();
      // }
    })

  }

  const [customers, setcustomers] = useState([])
  const [customerdetails, setcustomerdetails] = useState([])

  // const getCustomers = () => {
  //   var token = sessionStorage.getItem("token")
  //   // var _id=sessionStorage.setItem("_id")
  //   axios.get("http://103.180.121.214:3005/api/admin/jsr/user/getalluser", {
  //     headers: { Authorization: `Bearer ${token}` },
  //   }).then((res) => {
  //     console.log(res.data);
  //     setcustomers(res.data.usersResult)

  //   })
  // }

  const CustomersDetails = (usersResult) => {
    var token = sessionStorage.getItem("token");
    var paras = {
      _id: usersResult._id
    }
    console.log(paras)
    axios.post("https://api.digitalraiz.co.in/api/admin/jsr/user/show-customer-addedfiles", paras, {
      headers: { Authorization: `Bearer ${token}` },
    }).then((res) => {
      console.log(res.data)
      if (res.status === 200) {
        navigate('/Viewcust')
        setcustomerdetails(res.data)
      }

      // toast("Add successfully");
      // getAllNews()
      // // handleClose1();
      // handleClose();
      // // }
    })

  }


  const CustomersDelete = (usersResult) => {
    var token = sessionStorage.getItem("token");
    var paras = {
      _id: usersResult._id
    }
    // console.log(paras)
    axios.post("https://api.digitalraiz.co.in/api/admin/jsr/user/remove-customer-addedfiles", paras,{
      headers: { Authorization: `Bearer ${token}` },
    }).then((res) => {
      console.log("delete")
      if (res.status === 200) {
       
        // setcustomerdetails(res.data)
      }
    })

  }
  const manageDelete = (usersResult) => {
    const confirmBox = window.confirm("Do you want to Delete?");
    if (confirmBox === true) {
      CustomersDelete(usersResult);
    }
  };


  const handleView = (d) => {
    setcustomerdetails(d);
    CustomersDetails(d)
    // console.log('UpdataData--',d);
    sessionStorage.setItem('customerdetails', JSON.stringify(d));
    navigate('/Viewcust');
  }

  const [listPerPage] = useState(10);
  const [pageNumber, setPageNumber] = useState(0);


  const pagesVisited = pageNumber * listPerPage;
  const lists = customers.slice(pagesVisited, pagesVisited + listPerPage);
  const pageCount = Math.ceil(customers.length / listPerPage);
  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };

  return (
    <div style={{ background: "#ebeaea",padding:"111px 0 0 0" }}>
      {" "}
      <Box sx={{ display: "flex" }}  className="cardmrg">
        <Drawer></Drawer>
        <CssBaseline />
        <Box component="main" style={{ background: "#ebeaea" }} sx={{ flexGrow: 2, p: 4 }}>
          {/* <DrawerHeader /> */}
          <Row style={{height: "527px"}}>
            {/* <Col md={3} style={{ marginTop: "20px" }}>
              <Card>
                <CardBody>
                  <Media>
                    <Media body className="overflow-hidden">
                      <div className="row">
                        <div className="col-9">
                          <p className="text-truncate font-size-12 mb-1">
                            {" "}
                            Total Temples
                          </p>
                        </div>
                        <div className="col-3" style={{ color: "#dc3545" }}>
                          <TempleHinduIcon />
                        </div>
                      </div>
                      <h2 className="mb-0">{data1.templeCount}</h2>
                  
                    </Media>
                    <div className="text-primary">
                    </div>
                  </Media>
                </CardBody>
              </Card>
            </Col> */}
            {/* <Col md={4} style={{ marginTop: "20px" }}>
              <Card>
                <CardBody>
                  <Media>
                    <Media body className="overflow-hidden">
                      <div className="row">
                        <div className="col-9">
                          <p className="text-truncate font-size-12 mb-2">
                            {" "}
                            Total Projects
                          </p>
                        </div>
                        <div className="col-3" style={{ color: "#dc3545" }}>
                          <AccountTreeIcon />
                        </div>
                      </div>
                      <h2 className="mb-0">{data1.projectCount}</h2>
                     
                    </Media>
                    <div className="text-primary">
                    </div>
                  </Media>
                </CardBody>
              </Card>
            </Col> */}
            <Col md={4} style={{ marginTop: "20px" }}  >
              <Card className="dascard cardstyle" >
                <CardBody >
                  <Media>
                    <Media body className="overflow-hidden">
                      <div className="row">
                        <div className="col-6">
                          <p className="text-truncate font-size-12 mb-3" style={{fontWeight: 600}}>
                            {" "}
                            Total Leads 
                          </p>
                          <span style={{fontWeight: 600,fontSize: "25px"}} >{data1.totalLeadResult}</span>
                          <br/>
                          {/* <span className="text-success" style={{marginTop:"20px"}}>Total Cards</span> */}
                        </div>
                        <div className="col-6" style={{ color: "#7e3ee8" }}>
                          <div style={{float:"right"}}>
                          <HistoryIcon style={{fontSize: "30px"}} />
                          </div>
                        </div>
                      </div>
                      {/* <h2 className="mb-0">{data1.newsCount}</h2> */}
                      {/* <div className="card-footer">
                        <div className="row">
                          <div className="col-3">
                            <button
                              className="btn btn mr-5"
                              style={{ background: "#b8e3b8" }}
                            >
                              2.5%
                            </button>
                          </div>
                          <div className="col-8 offset-1 mt-2">
                            <span className="text-muted ">
                              From previous...
                            </span>
                          </div>
                        </div>
                      </div> */}
                    </Media>
                    <div className="text-primary">
                      <i>{/* <GroupIcon></GroupIcon> */}</i>
                    </div>
                  </Media>
                </CardBody>
              </Card>
            </Col>
            <Col md={4} style={{ marginTop: "20px" }}>
              <Card className="dascard cardstyle">
                <CardBody>
                  <Media>
                    <Media body className="overflow-hidden">
                      <div className="row">
                        <div className="col">
                          <p className="text-truncate font-size-12 mb-3" style={{fontWeight: 600}}>
                            {" "}
                            Today Leads 
                          </p>
                          <span style={{fontWeight: 600,fontSize: "25px"}} className="mt-4" >{data1.todayTotalLeadsCount}</span>
                        </div>
                        <div className="col" style={{ color: "#7e3ee8" }}>
                        <div style={{float:"right"}}>
                          <GroupIcon style={{fontSize: "30px"}} />
                          </div>
                        </div>
                      </div>
                      {/* <h2 className="mb-0">{data1.usersCount}</h2> */}
                      {/* <div className="card-footer">
                        <div className="row">
                          <div className="col-3">
                            <button
                              className="btn btn mr-5"
                              style={{ background: "#b8e3b8" }}
                            >
                              2.5%
                            </button>
                          </div>
                          <div className="col-8 offset-1 mt-2">
                            <span className="text-muted ">
                              From previous...
                            </span>
                          </div>
                        </div>
                      </div> */}
                    </Media>
                    <div className="text-primary">
                      <i>{/* <GroupIcon></GroupIcon> */}</i>
                    </div>
                  </Media>
                </CardBody>
              </Card>
            </Col>
            {/* <Col md={3} style={{ marginTop: "20px" }}>
              <Card className="dascard cardstyle" >
                <CardBody >
                  <Media>
                    <Media body className="overflow-hidden">
                      <div className="row">
                        <div className="col-6">
                          <p className="text-truncate font-size-12 mb-2" style={{fontWeight: 600}}>
                            {" "}
                            Total Cards 
                          </p>
                          <span style={{fontWeight: 600}} >12</span>
                          <br/>
                         </div>
                        <div className="col-6" style={{ color: "#8c7967" }}>
                          <div style={{float:"right"}}>
                          <HistoryIcon style={{fontSize: "30px"}} />
                          </div>
                        </div>
                      </div>
                    </Media>
                    <div className="text-primary">
                    </div>
                  </Media>
                </CardBody>
              </Card>
            </Col>
            <Col md={3} style={{ marginTop: "20px" }}>
              <Card className="dascard cardstyle">
                <CardBody>
                  <Media>
                    <Media body className="overflow-hidden">
                      <div className="row">
                        <div className="col">
                          <p className="text-truncate font-size-12 mb-2" style={{fontWeight: 600}}>
                            {" "}
                            Total Admins
                          </p>
                          <span style={{fontWeight: 600}} >5</span>
                        </div>
                        <div className="col" style={{ color: "#8c7967" }}>
                        <div style={{float:"right"}}>
                          <GroupIcon style={{fontSize: "30px"}} />
                          </div>
                        </div>
                      </div>
                     
                    </Media>
                    <div className="text-primary">
                    </div>
                  </Media>
                </CardBody>
              </Card>
            </Col> */}
            
          </Row>
          {/* <Row style={{ marginTop: "40px" }}>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <h6> Latest Customers </h6>
                  <div className="table-responsive mt-3">
                    <Table
                      bordered
                      className="table-centered datatable dt-responsive nowrap "
                      style={{
                        borderCollapse: "collapse",
                        borderSpacing: 0,
                        width: "100%",
                      }}
                    >
                      <thead
                        className="thead-light"
                        style={{ background: "#1562a0", color: "white" }}
                      >
                        <tr>
                          <th>S.no</th>
                          <th>Customer</th>
                          <th>Email</th>
                          <th>Phone</th>
                          <th>Joining Date</th>
                          <th style={{ width: "120px" }}>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {lists.map((usersResult, key) => (
                          <tr key={key}>
                            <td>{key + 1}</td>
                            <td>{usersResult.username}</td>
                            <td>{usersResult.email}</td>
                            <td>{usersResult.contactNumber}</td>
                            <td>{usersResult.createdAt}</td>
                            <td>
                              <Link
                                to=""
                                className="mr-3 text-primary"
                                id={"view" + key}
                                onClick={() => handleView(usersResult)}

                              >
                                <VisibilityIcon />

                              </Link>
                              <UncontrolledTooltip
                                target={"view" + key}
                                placement="top"
                              >
                                View
                              </UncontrolledTooltip>
                              <Link
                                to="#"
                                className="text-danger"
                                id={"delete" + key}
                                onClick={() => manageDelete(usersResult._id)}
                              >
                                <DeleteOutlineIcon></DeleteOutlineIcon>
                              </Link>
                              <UncontrolledTooltip
                                target={"delete" + key}
                                placement="top"
                              >
                                Delete
                              </UncontrolledTooltip>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                    <div class="text-right mb-3" style={{ marginTop: "-40px", float: "right", marginRight: "30px" }}>
                   
                      <ReactPaginate
                        previousLabel={"Previous"}
                        nextLabel={"Next"}
                        pageCount={pageCount}
                        onPageChange={changePage}
                        containerClassName={"pagination"}
                        previousLinkClassName={"previousBttn"}
                        nextLinkClassName={"nextBttn"}
                        disabledClassName={"disabled"}
                        activeClassName={"active"}
                      />
                    
                    </div>

                

                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row> */}
        </Box>
      </Box>
    </div>
  );
};

export default Dashboard;
