import React from "react";
import IndeterminateCheckBoxIcon from "@mui/icons-material/IndeterminateCheckBox";
import CardMembershipIcon from "@mui/icons-material/CardMembership";
import DesignServicesIcon from "@mui/icons-material/DesignServices";
import ClosedCaptionIcon from "@mui/icons-material/ClosedCaption";
import AnnouncementIcon from "@mui/icons-material/Announcement";
import CallToActionIcon from "@mui/icons-material/CallToAction";
import ViewCarouselIcon from "@mui/icons-material/ViewCarousel";
import ViewArrayIcon from "@mui/icons-material/ViewArray";
import DashboardIcon from "@mui/icons-material/Dashboard";
import GraphicEqIcon from "@mui/icons-material/GraphicEq";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import ViewDayIcon from "@mui/icons-material/ViewDay";
import ShieldIcon from "@mui/icons-material/Shield";
import QuizIcon from "@mui/icons-material/Quiz";

const Drawerdata = [
  {
    title: "Dashboard",
    icon: <DashboardIcon />,
    link: "/admin",
  },
  {
    title: "Leads",
    icon: <ViewCarouselIcon />,
    link: "/Leads ",
  },

  {
    title: "Header Section",
    icon: <ViewArrayIcon />,
    link: "/Headersection",
  },
  {
    title: "About Section",
    icon: <IndeterminateCheckBoxIcon />,
    link: "/About ",
  },

  {
    title: "Services",
    icon: <DesignServicesIcon />,
    link: "/services ",
  },
  {
    title: "Perfection",
    icon: <ViewDayIcon />,
    link: "/Perfection ",
  },

  {
    title: "Technology",
    icon: <ClosedCaptionIcon />,
    link: "/technologies ",
  },

  {
    title: "Clients",
    icon: <GroupAddIcon />,
    link: "/clients ",
  },

  {
    title: "Certification",
    icon: <CardMembershipIcon />,
    link: "/Certification ",
  },
  {
    title: "Testimonial",
    icon: <QuizIcon />,
    link: "/Testimonial",
  },
  {
    title: "Hire",
    icon: <ShieldIcon />,
    link: "/Administrations ",
  },

  {
    title: "Portfolio",
    icon: <AnnouncementIcon />,
    link: "/Portfolio ",
  },

  {
    title: "Footer Section",
    icon: <CallToActionIcon />,
    link: "/Footersection ",
  },
];
export default Drawerdata;
